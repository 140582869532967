.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100svh;
    background-color: #77777738;
    z-index: 8;
}


.mainContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100svh;
}
.mainContent{
    height: 55svh;
    width: 100%;
    background-color: white;
    overflow: hidden;

    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;

}



.fdHead{
    height: 5svh;
    display: flex;
    align-items: center;
}
.closeBtnWrapper{
    width: 10%;
}
.closeBtnWrapper button{
    background-color: transparent;
    border: none;
}
.headTitle{
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.fDCols{
    width: 92%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50svh;
    padding: 0px 4%;
}


.netPayableNow{
    font-size: 4rem;
    font-weight: 600;
    margin-top: 10px;
}

.netPayableNowWrapper{
    text-align: center;
}


.dByWrapper{
    display: flex;
    border: 1px solid #dfdfdf;
    overflow: hidden;
    margin: 10px 0px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
.defaultDy{
    padding: 10px 16px;
    cursor: pointer;
    font-size: 0.8rem;
}
.activeDy{
    padding: 10px 16px;
    background-color: green;
    color: white;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
.discountInputWrapper{
    width: 100%;
    text-align: center;
    padding: 10px 0px;
}
.discountInputWrapper input{
    padding: 8px 0px;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.discountInputWrapper input::placeholder{
    font-size: 1rem;
    font-weight: 400;
}
.applyBtnWrapper{
    padding: 8px 0px;
    width: 100%;
    text-align: center;
}
.applyBtnWrapper button{
    padding: 10px 30px;
    background-color: black;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    width: 150px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}