.mainWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #00000050;
    z-index: 4;
}

.mainContainer {
    display: flex;
    position: relative;
    justify-content: space-between;
}
.closeBtn{
    height: 42px;
    width: 42px;
    border-radius: 42px;
    -webkit-border-radius: 42px;
    -moz-border-radius: 42px;
    -ms-border-radius: 42px;
    -o-border-radius: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 10px;
}
.closeBtn button{
    border: 0px;
    background-color: transparent;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContent {
    width: 35%;
    background-color: white;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.mainHeader {
    height: 7vh;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    text-align: center;
    align-items: center;
    display: flex;
    padding: 0px 20px;
    background: #027fff;
    color: white;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;

}
.bookingDeleted{
    height: 86vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mainBody {
    height: 86vh;
    overflow-y: scroll;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px 3%;
}

.actionsWrapper {
    padding: 0px 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    width: 94%;
}

.mainFooter {
    height: 7vh;

}
.eachActionWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 30px 0px;
}
.eachActionWrapper:not(:last-child) {
    border-bottom: 1px solid #dfdfdf;
    
}

.changeBookingDate {
    border: 1px solid #dfdfdf;
    display: flex;
    padding: 0px 0px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: calc(100% - 0px);
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}
.dateInputTxt{
    font-size: 0.8rem;
    padding: 0px 10px;
    font-weight: 600;
}
.dateInputWrapper {
    width: 50%;
    background-color: #dfdfdf;
}
.updateDate{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px;
}
.updateDate button{
    padding: 8px 30px;
    border: 1px solid green;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: white;
    font-weight: 500;
    background-color: green;
}


.dateInputWrapper input {
    width: calc(100% - 22px);
    padding: 12px 10px;
    border: none;
    background-color: #dfdfdf;
}


.discardBooking {
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: red;
    padding: 9px 15px;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    font-size: 0.8rem;
}

.actionIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}