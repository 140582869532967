.mainWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainContainer {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainContent {
    background-color: white;
    width: 50%;
    height: 60vh;
    border-radius: 14px;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.1s ease-out, transform 0.2s ease-out;
    -webkit-transition: opacity 0.1s ease-out, transform 0.2s ease-out;
    -moz-transition: opacity 0.1s ease-out, transform 0.2s ease-out;
    -ms-transition: opacity 0.1s ease-out, transform 0.2s ease-out;
    -o-transition: opacity 0.1s ease-out, transform 0.2s ease-out;
    animation: slideUp 0.1s ease-out forwards;
    -webkit-animation: slideUp 0.1s ease-out forwards;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

/* Keyframes for sliding up */
@keyframes slideUp {
    from {
        opacity: 0;
        /* Start invisible */
        transform: translateY(100%);
        /* Start from bottom */
    }

    to {
        opacity: 1;
        /* End visible */
        transform: translateY(0);
        /* End in place */
    }
}


.collectBalance {
    padding: 10px;
    font-size: 0.8rem;
    color: #818181;
    font-weight: 500;
}

.amountInput input {
    padding: 10px;
    text-align: center;
    font-size: 1.5rem;
    border: none;
    border-bottom: 1px solid #dfdfdf;
}

.amountInput input::placeholder {
    font-size: 0.9rem;
}

.actionsWrapper {
    padding: 20px 0px 0px;
}

.updateBtn {
    padding: 4px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.enabled  {
    padding: 11px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #027ff024;
    color: #027fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #027ff0;
}
.disabled{
    padding: 11px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dfdfdf;
    color: #00000060;
    font-weight: 500;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #dfdfdf;
}
.cancelBtn {
    padding: 10px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancelBtn button {
    padding: 6px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    color: red;
    font-weight: 500;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.modeWrapper{
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.eachModeWrapper{
    display: flex;
    gap: 50px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
}
.emwTitle{
    flex: 1;
    background-color: #dfdfdf;
    padding: 10px;
}
.emwValueWrapper{
    padding: 5px 8px;
}
.emwValue{
    flex: 1;
}
.emwValue input{
    padding: 7px 6px;
    text-align: right;
    border: none;
    border-bottom: 1px solid #dfdfdf;
}
.txnWrapper{
    padding: 5px 0px;
}
.txnWrapper input{
    padding: 4px 6px;
    text-align: right;
    border: none;
    border-bottom: 1px solid #dfdfdf;
}