.mainWrapper{
    width: 100%;
    height: 100svh;
    background-color: black;
}
.mainContainer{
    width: 100%;
    height: 100svh;
    
}
.headerWrapper{
    height: 7svh;
    color: white;
    font-weight: 600;
    align-items: center;
    display: flex;
    padding: 0px 0px 0px 4%;
}
.bodyWrapper{
    height: 93svh;
    background-color: #6d6d6d;
    overflow-y: scroll;
    /* background-color: white; */
}

.cardWrapper{
    /* height: 85svh; */
    width: 92%;
    padding: 5svh 4% 3svh;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;


    
}
.eachCardMainWrapper{
    cursor: pointer;
    padding: 0px 0px 20px 0px;
}
.eachCardWrapper{
    margin: 0px 0px;
    /*width: 351px;
    height: 207px;*/
    
    overflow: hidden;
    width: 37.8svh;
    height: 22svh;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #b8b8b8;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}


.cardTitleWrapper{
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 10px 10px;
    height: 20px;
}

.cardName{
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    text-shadow: 0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.7);

}

.eachCardDetailsSection{
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* border-bottom: 1px solid #dfdfdf9c; */
    padding: 30px 2% 10px 2%;
    position: absolute;
    top: 130px;
    width: 96%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    /* background-color: rgba(0, 0, 0, 0.274); */
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.568), black);
}


.savingContainer{
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    font-weight: 600;
    background-color: rgba(255, 0, 0, 0.685);
    padding: 2px 8px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.priceContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.benefitPrice{
    font-size: 1.2rem;
    font-weight: 700;
    color: aqua;
}
.payableAmount{
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    display: flex;
    text-align: right;
    width: 100%;
    padding-left: 40px;
}











.cardDetailsBigWrapper{
    width: 100%;
    padding: 0px 0px 0px;
}
.cdbwHeader{
    width: 100%;
    height: 6svh;
    background-color: #5f5f5f;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.backBtnWrapper{
    width: 3%;
    text-align: center;
    cursor: pointer;
}
.allCardsWrapper{
    width: 97%;
    display: flex;
}
.eachSmallCardTitle{
    padding: 4px 15px;
    background-color: white;
    margin: 0px 8px;
    width: 100px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.eachSmallCardTitleSelected{
    padding: 4px 15px;
    background-color:#FB651F;
    color: white;
    margin: 0px 8px;
    width: 100px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}

