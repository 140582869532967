.mainWrapper{
    width: 100%;
    height: 93svh;
}

.header{
    height: calc(6svh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
}
.body{
    height: 79svh;
    background-color: white;
}
.footer{
    height: calc(8svh - 1px);
    border-top: 1px solid #dfdfdf;
}


.totalSavingLine{
    display: flex;
    align-items: center;
    width: 92%;
    border: 1px solid #027FFF;
    margin: 0px 3%;
    background-color: #027FFF20;
    padding: 5px 1%;

    font-size: 0.8rem;
    font-weight: 600;

    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.tslIcon{
    width: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tsLv{
    width: 94%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}


.cartBody{
    width: 100%;
    max-height: 40svh;
    overflow-y: scroll;
}
.calBody{
    background-color: #e2e2e2;
    border-bottom: 1px solid white;
}



.collectPaymentWrapper{
    width: 94%;
    height: calc(8svh - 1px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 3%;
}
.collectPaymentBtn{
    background-color: #027FFF;
    border: 1px solid #027FFF;
    color: white;
    padding: 8px 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;

    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


.servicesTotal{
    display: flex;
    justify-content: space-between;
    padding: 5px 2%;
    font-weight: 600;
}

.addFlatDiscount{
    width: 100%;
    background-color: #cccccc78;
}
.addFlatDiscount button{
    width: calc(100% - 20px);
    padding: 8px 10px;
    background-color: transparent;
    border: none;
}

.allDiscountsWrapper{
    padding: 0px 4%;
}
.eachDiscountRow{
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    font-weight: 500;
    color: #686868;
}
.totalDiscountRow{
    margin: 5px 0px;
    padding: 5px 2%;
    border-top: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.grandTotalRow{
    display: flex;
    justify-content: space-between;
    padding: 5px 2%;
    font-weight: 600;
}


.clientWrapper{
    width: 96%;
    padding: 8px 2%;
}
.clientDetailsContainer{
    border: 1px solid #dfdfdf;
    padding: 7px 2%;
    background-color: #dadada;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

    cursor: pointer;
}
.cdcUpperLayer{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}
.clientName{
    text-transform: uppercase;
    font-size: 14px;

}

.noItemInCart{
    height: 70svh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    color: #686868;
    padding: 0px 20%;
    text-align: center;
}