.mainWrapper{
    width: 100%;
    background-color: #FAFAFB;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    height: 100svh;
}
.hamLogoWrapper{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 4%;
    height: 9vh;
}
.logoWrapper{
    flex: 8;
}
.logoWrapper img{
    height: 40px;
}
.menuIcon{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.menuHolder{
    height: 75vh;
    overflow-y: scroll;
}
.sectionTitle{
    padding: 5px 10px;
    font-weight: 600;
    font-size: 9px;
    text-transform: uppercase;
}
.eachNavWrapper{
    display: flex;
    padding: 0px 5%;
    justify-content: center;
    align-items: flex-start;
}
.eachNavWrapperSmall{
    display: flex;
    padding: 0px 10%;
    justify-content: center;
    align-items: flex-start;
}
.eachNavWrapperSmall .eachNavContainer{
    justify-content: center;
}
.eachNavContainer{
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    display: flex;
    align-items: center;
    flex: 1;
    gap: 8px;
    padding: 10px 10px;
    justify-content: flex-start;
}
.eachNavContainer:hover{
    background-color: #E8E9F4;
}
.navIcon{
    display: flex;
    align-items: center;
}
.navTxt{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.8rem;
}


.hfWrapper{
    padding: 0px 0px 10px;
    cursor: pointer;
}

.changeActiveStore{
    display: flex;
    justify-content: center;
    background-color: aliceblue;
    margin: 0px 10%;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.changeActiveStore:hover{
    background-color: #E8E9F4;
}
.changeActiveStore .eachNavWrapper:hover{
    background-color: transparent;
}
.changeActiveStore .eachNavContainer:hover{
    background-color: transparent;
}