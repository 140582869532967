.mainWrapper{
    width: 100%;
    position: fixed;
    height: 100svh;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: rgba(44, 44, 44, 0.377);
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContainer{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    width: 50%;
    height: 60svh;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
}
.mainHeader{
    height: 6svh;
    background-color: #cfcfcf;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;

}
.mainBody{
    height: 49svh;
    overflow-y: scroll;
}
.mainFooter{
    height: 5svh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainFooter button{
    background-color: transparent;
    border: none;
    font-weight: 600;
    color: #7c7c7c;
    letter-spacing: 1.2px;
}
