.mainWrapper{
    width: 100%;
}
.graphWrapper{
    width: 100%;
}
.eachBarWrapper{
    width: 100%;
    display: flex;
    height: 30px;
    align-items: flex-start;
    margin: 0px 0px 5px 0px;

    /* border: 1px solid red; */
}
.title{
    width: 5%;
    display: flex;
    align-items: center;
    height: 100%;
}
.barWrapper{
    width: 80%;
    position: relative;
}
.emptyBar{
    width: 100%;
    background-color: #dddddd38;
    height: 30px;
    position: absolute;
    top: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.filledBar{
    width: 40%;
    background-color: green;
    height: 30px;
    position: absolute;
}
.legends{
    width: 10%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
}