.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100svh;
    width: 100%;
    z-index: 600;
}
.mainContainer{
    position: relative;
    height: 90svh;
    width: 96%;
    background-color: #000000bf;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0px 0px 10svh 4%;
}
.mainContent{
    background-color: white;
    height: 70svh;
    width: 22%;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    animation: open 0.2s cubic-bezier(0, 1.15, 0.66, 0.98);
    -webkit-animation: open 0.2s cubic-bezier(0, 1.15, 0.66, 0.98);
    overflow: hidden;
}
@keyframes open {
    0% { height: 0svh; width: 0%; }
    100% { height: 70svh; width: 22%;}
}

.topHeader{
    height: 6svh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dfdfdf40;
    border-bottom: 1px solid #dfdfdf;
    font-weight: 500;
    font-size: 0.8rem;
}
.storesWrapper{
    height: 64svh;
    overflow-y: scroll;
}
.eachStoreWrapper{
    padding: 10px 3%;
    border-bottom: 1px solid #dfdfdf;
    cursor: pointer;
}
.storeName{
    font-size: 0.9rem;
    font-weight: 600;
    color: #3b3b3b;
}
.storeAddress{
    font-size: 0.7rem;
    color: #848484;
}