.bookingsWrapper{
    height: 89svh;
    width: 70%;
    
}
.bookingsHeader{
    width: 70%;
    height: 8svh;
}
.bookingsContainer{
    height: 81svh;
    overflow-y: scroll;
    
}
.eachBookingRow{
    display: flex;
    justify-content: space-between;
    padding: 4px 2% 4px;
    margin: 0px 1% 10px 0px;
}