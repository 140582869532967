.mainWrapper{
    width: 94%;
    padding: 8px 3%;
    border-bottom: 1px solid #dfdfdf;
    font-size: 13px;
}
.mainContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
}