.mainWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80svh;
    gap: 10px;
}
.oops{
    font-weight: 600;
    font-size: 2.3rem;
    color: #027fff;
    padding: 0px 0px 20px;
}
.rest{
    color: #949494;
    font-size: 0.8rem;
    font-weight: 500;
}
.rest a{
    color: #027fff;
}