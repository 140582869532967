.mainWrapper{
    max-height: 120px;
    padding: 6px 8px;
}
.mainContainer{
    border: 1px solid #dfdfdf;
    padding: 10px 0px;
    background-color: whitesmoke;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: grab;
    /* cursor: alias; */
    display: flex;

}
.checkBoxIcon{
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.address{
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000090;
}