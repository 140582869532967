.mainWrapper{
    height: 82svh;
    width: 100%;
    background-color: aliceblue;
}

.mainHeader{
    width: 97%;
    border-bottom: 1px solid #dfdfdf;
    height: calc(27svh - 1px);
    padding: 0px 1.5%;
}
.navigationBtn{
    height: 6svh;
    padding: 0px 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.leftCol{
    width: 70%;
}
.rightCol{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
.bookingDetails{
    height: 20px;
    padding: 0px 10px 0px 0px;
    /* background-color: white; */
    /* border: 1px solid #dfdfdf; */
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    text-transform: uppercase;
}
.activeBookings{
    height: 20px;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: white;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    text-transform: uppercase;
    flex-wrap: wrap;
    font-size: 0.6rem;
    font-weight: 600;
    margin: 0px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.moreBtn{
    height: 32px;
    width: 32px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


.bookingSummary{
    height: calc(15svh - 4px);
    border-bottom: 4px solid #dfdfdf;
    padding: 0px 0%;
    display: flex;
    justify-content: space-between;
}
.clientName{
    font-size: 1.6rem;
    font-weight: 600;
}
.gender{
    font-size: 0.7rem;
    font-weight: 600;
    color: #00000070;
}

.visitorCountWrapper{
    display: flex;
    
}
.eachVCBox{
    height: 50px;
    width: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.counterBadge{
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 0.8rem;
    font-weight: 600;
}

.timeLapsedWrapper{
    text-align: center;
}
.timeLapsedHeader{
    text-transform: uppercase;
    font-weight: 600;
    padding: 5px 0px;
    font-size: 0.7rem;
}
.timeLapsedBody{
    display: flex;
}
.eachTLUnit{
    text-align: center;
    width: 60px;
}
.tlUnitValue{
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0px 20px;
}
.tlUnitCaption{
    font-size: 0.6rem;
}


.actionBtns{
    padding: 0px 0%;
    display: flex;
    align-items: flex-end;
    height: 5svh;
}
.defaultBtn{
    border: 1px solid transparent;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    padding: 2px 10px 10px;
    margin: 0px 20px 0px 0px;
    font-weight: 600;
    color: #00000080;
    text-transform: uppercase;
}
.activeBtn{
    border: 1px solid transparent;
    border-bottom: 2px solid #027FFF;
    background-color: transparent;
    padding: 2px 10px 10px;
    margin: 0px 20px 0px 0px;
    font-weight: 600;
    color: #00000080;
    text-transform: uppercase;
    color: #027FFF;
}


.bottomTotalWrap{
    background-color: white;
    width: 100%;
    height: 65vh;
}
.bottomTotalContainer{
    padding: 10px 1%;
    width: 98%;
    height: calc(65vh - 20px);
}
.grandTotalWrapper{
    display: flex;
    width: 98%;
    justify-content: space-between;
    text-transform: uppercase;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 1%;
    font-size: 13px;
    font-weight: 500;
}
.modesWrapper{
    width: 98%;
    display: flex;
    justify-content: flex-end;
    padding: 0px 1%;
}
.modeContainer{
    width: 30%;
    text-transform: uppercase;
    font-size: 13px;
}
.eachModeRow{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 0px;
}


.feedbackWrapper{
    background-color: white;
    width: 100%;
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
}