.mainWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100svh;
}

.customerRegisterationForm {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px 0px 0px;
}

.crfContainer {
    background-color: white;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding: 10px 0px;
    border: 1px solid #dfdfdf;
    margin-top: 0px;
}

.crfTitle {
    text-align: center;
    font-weight: 500;
    font-size: 1.4rem;
    padding: 10px 0px;
}

.inputWrappers {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 30px;
}

.eachInputWrapper {
    display: flex;
    justify-content: space-between;
}

.countryCode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.countryCode label{
    display: none;
}
.countryCode select {
    padding: 8px 0px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: 80px;
}

.phoneNumber {
    flex: 9;
    display: flex;
    justify-content: flex-end;
}
.phoneNumber label{
    display: none;
}
.phoneNumber input {
    height: 34px;
    width: 34px !important;

    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.nameInputWrapper {
    display: flex;
    gap: 5px;
    flex: 1;
    width: 100%;
}

.nameInputEach {
    flex: 1;
}

.nameInputWrapper input {
    padding: 12px 5px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: calc(100% - 12px);
}

.emailInputWrapper input {
    padding: 12px 5px;
    width: calc(100% - 12px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.addressInputWrapper textarea {
    padding: 12px 5px;
    width: calc(100% - 10px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.dateInputWrapper {
    display: flex;
    gap: 10px;
}

.dateInputWrapper input {
    padding: 12px 5px;
}

.dobWrapper {
    flex: 1;
}
label{
    font-weight: 500;
    font-size: 0.7rem;
}
.dobWrapper input {
    width: calc(100% - 12px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

}

.registerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.registerWrapper button {
    background-color: #1E7BF4;
    color: white;
    font-weight: 500;
    padding: 12px;
    width: 100%;
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.spareWrapper{
    height: 0px;
}

.cityPincodeStateWrapper{
    display: flex;
    width: 100%;
    gap: 10px;
}
.cityWrapper{
    flex: 1;
}
.cityWrapper input{
    padding: 12px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.pincodeWrapper{
    flex: 1;
}
.pincodeWrapper input{
    padding: 12px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
}
.stateWrapper{
    flex: 1;
}
.stateWrapper input{
    padding: 12px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
}


@media only screen and (max-width: 600px) {
    .mainWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 86svh;
        flex-direction: column;
        padding: 2svh 0px 12svh 0px;
    }

    .customerRegisterationForm {
        width: 93%;
        height: 85svh;
        padding: 0px 0px 0px 0px;
    }
    .crfContainer{
        height: 85svh;
        padding: 0px;
        margin: 0px;
        overflow: hidden;
        border: 1px solid #1E7BF440;
    }
    .crfTitle{
        height: 7svh;
        border-bottom: 1px solid #1E7BF440;
        padding: 0px;
        margin: 0px;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1E7BF420;
    }
    .inputWrappers {
        padding: 14px 14px 14px;
        gap: 10px;
        height: calc(76svh - 28px);
        overflow-y: scroll;
    }
    .eachInputWrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
    }

    .countryCode {
        padding: 0px;
    }

    .countryCode select {
        width: 100%;
        padding: 12px 0px;
        height: 44px;
        background-color: white;

    }


    .nameInputWrapper {
        flex-direction: column;
        gap: 10px;
    }

    .phoneNumber {
        flex: 9;
        display: flex;
        justify-content: flex-end;
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        overflow: hidden;
        border: 1px solid #dfdfdf;
        flex: 1;
    }

    .phoneNumber input {
        height: 40px;
        flex: 1 !important;
        width: 24px !important;
        border: 1px solid transparent;
        border-bottom: 1px solid transparent;
        background-color: transparent;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
        color: black;
        font-weight: 500;
        text-align: center;
    }

    .phoneNumber div{
        flex: 1;
    }
    .phoneNumber div div{
        flex: 1;
    }
    .registerWrapper {
        position: fixed;
        bottom: 0px;
        left: 0px;
        background-color: white;
        width: 90%;
        height: 10svh;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #dfdfdf;
        padding: 0px 5%;
    }


    .registerWrapper button {
        background-color: #1E7BF4;
        color: white;
        font-weight: 500;
        padding: 16px;
        width: 100%;
        border: none;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
    }
    .dobWrapper{
        width: 100%;
    }
    .dobWrapper input{
        height: 38px;
        padding: 0px;
        width: 100% !important;
        min-width: 140px;
    }
    .cityPincodeStateWrapper{
        flex-direction: column;
        width: 100%;
    }
    .cityWrapper div{
        width: 100%;
    }
    .cityWrapper div input{
        width: calc(100% - 16px);
    }
    .pincodeWrapper div{
        width: 100%;
    }
    .pincodeWrapper div input{
        width: calc(100% - 16px);
    }
    .stateWrapper div{
        width: 100%;
    }
    .stateWrapper div input{
        width: calc(100% - 16px);
    }
}