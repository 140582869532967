.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 4;
    width: 100%;
    height: 100svh;
    background-color: #313131e3;
}
.mainContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100svh;
}
.mainCalendarBox{
    height: 50svh;
    width: 60%;
    background-color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.calendarContainer{
    height: 50svh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #E8EAED;
}
.ccLeft{
    width: 30%;
    background-color: white;
    overflow-y: scroll;
    height: 50svh;
}
.ccRight{
    width: 70%;
    height: 50svh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.eachBtnWrapper{
    padding: 8px 2%;
    background-color: #E8EAED;
    margin: 4px 1%;
    font-size: 0.8rem;
    cursor: pointer;
}
.dateHeader{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.eachCalTitle{
    font-weight: 600;
    padding: 8px 0px 8px;
    text-align: center;
}
.eachCalendar{
    background-color: transparent !important;
    border: none !important;
    font-family: 'Montserrat' !important;
}



@media only screen and (max-width: 600px) {
    .mainCalendarBox{
        height: 86svh;
        width: 80%;
    }
    .calendarContainer{
        height: 86svh;
        flex-direction: column;
    }
    .ccLeft{
        width: 100%;
        /* display: flex; */
        display: none;
        height: 6svh;
        overflow-y: scroll;
    }
    .eachBtnWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ccRight{
        height: 80svh;
        width: 100%;
    }
    .eachCalTitle{
        font-size: 0.8rem;
    }
    .dateHeader{
        height: 80svh;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: scroll;
    }
    .eachDateWrapper{
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #ffffff;
    }
    .eachDateWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}