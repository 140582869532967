.mainWrapper{
    background-color: #FFFFFF;
    flex: 1;
    height: 7vh;
}
.mainContainer{
    display: flex;
    align-items: center;
    height: 7vh;
    padding: 0px 1.5%;
}
.leftCol{
    flex: 2;
}
.leftCol input{
    padding: 7px 10px;
    background-color: #F9F9F9;
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 500;
    font-size: 0.8rem;
    width: 60%;
}
.rightCol{
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.userWrapper{
    display: flex;
    align-items: center;
    gap: 10px;
}
.userIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.userContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.username{
    font-weight: 500;
    font-size: 1rem;
}
.userdes{
    font-size: 0.6rem;
    font-weight: 500;
    color: #00000080;
}