.mainWrapper{
    padding: 10px 10px;
    width: calc(95% - 20px);
    display: flex;
    align-items: center;
}
.bookingId{
    width: 8%;
    font-weight: 500;
}
.clientName{
    width: 28%;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
}
.userIcon{
    height: 36px;
    width: 36px;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    background-color: #027fff30;
    display: flex;
    align-items: center;
    justify-content: center;
}
.createdOn{
    width: 13%;
    font-size: 14px;
    font-weight: 500;
}
.total{
    width: 10%;
    font-size: 14px;
    font-weight: 500;
}
.currentStatus{
    width: 20%;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}
.success{
    color: green;
}
.servicesInCart{
    width: 20%;
    font-size: 14px;
    font-weight: 500px;
    text-transform: uppercase;
}