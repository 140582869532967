.mainWrapper {
    width: 100%;
    height: 100svh;
    background-color: #F8FAFF;
    color: white;
    display: flex;
    justify-content: space-between;
}

.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100svh;
}

.mainContent {
    padding: 0px 35%;
    width: 30%;
}

.mainLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 20px;
}

.mainLogo img {
    height: 100px;
}

.titleHeader {
    padding: 0px 0px 20px;
}

.title {
    text-align: center;
}

.title h3 {
    padding: 0px;
    margin: 0px;
    color: black;
    font-weight: 500;
    font-size: 2rem;
}

.subTitle {
    color: #4e4e4e80;
    text-align: center;
    font-weight: 500;
    font-size: 0.7rem;
}

.loginSection {
    width: 86%;
    background-color: #FFFFFF;
    padding: 0px 7%;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.loginResponse {
    color: #303030;
    font-weight: 600;
    text-align: right;
    padding: 10px 0px 0px 0px;
}

.inputWrapper {
    width: 100%;
    padding: 18px 0px;
    /* border: 1px solid #d4d4d4; */
}

.inputWrapper input {
    padding: 12px 4%;
    width: calc(92% - 2px);
    border: 1px solid #d4d4d4;
    border-radius: 48px;
    -webkit-border-radius: 48px;
    -moz-border-radius: 48px;
    -ms-border-radius: 48px;
    -o-border-radius: 48px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

.passwordWrapper {
    width: 100%;
    padding: 18px 0px;
}

.passwordContainer {
    width: calc(92% - 2px);
    display: flex;
    justify-content: center;
    background-color: #E9EFF6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 48px;
    -webkit-border-radius: 48px;
    -moz-border-radius: 48px;
    -ms-border-radius: 48px;
    -o-border-radius: 48px;
    padding: 0px 4% 0px 2%;
    background-color: white;
    border: 1px solid #d4d4d4;
    overflow: hidden;
}

.togglePass {
    cursor: pointer;
}

.passwordWrapper input {
    padding: 12px 4px;
    width: calc(100% - 10px);
    border: 1px solid transparent;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


.forgotPassWrapper {
    display: flex;
    justify-content: space-between;
    color: #4e4e4e80;
}

.rememberWrapper {
    display: flex;
    align-items: center;
    color: #4e4e4e80;
    font-size: 0.6rem;
    font-weight: 500;
    cursor: pointer;
}

.forgotPassContainer {
    color: #50B5FF;
    font-size: 0.7rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.forgotPassContainer a {
    color: #50B5FF;
}


.loginBtnWrapper {
    width: 100%;
    padding: 18px 0px;
}

.loginBtnWrapper button {
    background-color: #007FFF;
    color: white;
    font-weight: 700;
    letter-spacing: 1.2px;
    padding: 12px 2%;
    width: calc(100% - 0px);
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 48px;
    -webkit-border-radius: 48px;
    -moz-border-radius: 48px;
    -ms-border-radius: 48px;
    -o-border-radius: 48px;
    /*box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.loginBtnWrapper button:focus {
    outline: none;
}

.dividerLineWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;
}

.justLine {
    border: 1px solid rgb(167, 166, 166);
    display: flex;
    width: 50%;
}

.txtBetweenLine {
    padding: 0px 10px;
    font-weight: 600;
    width: 180px;
    text-align: center;
    color: black;
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .mainWrapper {
        flex-direction: column;
    }

    .mainContent {
        padding: 0px 5%;
        width: 90%;
    }

    .loginSection {
        padding: 0px 10%;
        width: 80%;
    }

    .leftContainer p {
        font-weight: 500;
        color: #4e4e4e;
        font-size: 0.8rem;
        text-align: justify;
    }
}