
.eachClientWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfdfdf46;
    padding: 5px 0px;
    cursor: pointer;
    gap: 10px;
    flex-direction: column;
}
.clientSummaryWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0px;
    cursor: pointer;
    gap: 10px;
}
.userIcon{
    width: 40px;
    height: 40px;
    display:  flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #027FFF;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    background-color: #027FFF20;
}
.clientDetails{
    flex: 2;
}
.clientName{
    font-size: 0.9rem;
    font-weight: 600;
}
.clientPhone{
    font-size: 0.7rem;
}
.clientRemarks{
    font-size: 0.5rem;
}
.fmCountWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.fmCountContainer{
    font-size: 0.7rem;
    background-color: #027FFF30;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 4px 8px;
    color: #027FFF;
    font-weight: 500;
}

.eachRelative{
    display: flex;
    gap: 10px;
    font-size: 0.7rem;
    justify-content: flex-start;
    align-items: center;
    padding: 3px 0px;
}
.membersWrapper{
    width: 86%;
    padding: 0px 7%;
}
.mwTitle{
    font-size: 0.7rem;
    font-weight: 600;
    color: #818181;
    text-align: center;
}
.rFullName{
    flex: 4;
}
.rAge{
    flex: 2;
}
.rGender{
    flex: 1;
}
.relationToFamilyHead{
    flex: 2;
}