.mainWrapper{
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #027FFF30;
    height: 5svh;
    align-items: center;
    background-color: white;
}
.mainWrapperEditable{
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #027FFF30;
    height: 5svh;
    align-items: center;
    background-color: aliceblue;
}
.sn{
    flex: 0.5;
    text-align: center;
}
.pageUrl{
    flex: 4;
}
.status{
    flex: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.actions{
    flex: 2.8;
    display: flex;
    gap: 10px;
}


.pageUrl input{
    width: calc(100% - 10px);
    padding: 10px 5px;
    border: none;
    font-weight: 500;
    font-style: italic;
    background-color: transparent;
    color: #027FFF;
}
.pageUrlTxt{
    font-size: 0.8rem;
    font-weight: 500;
}
.activeTag{
    border: 1px solid #88C529;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    padding: 3px 14px;
    font-size: 0.7rem;
    font-weight: 600;
    color: #88C529;
    background-color: white;
}
.inactiveTag{
    border: 1px solid #ff5202;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    padding: 3px 14px;
    font-size: 0.7rem;
    font-weight: 500;
    background-color: white;
}


.deactivate button{
    padding: 6px 16px;
    font-weight: 500;
    font-size: 0.7rem;
    border: 1px solid #027FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: white;
    color: #027FFF;
}
.editing{
    display: flex;
    gap: 10px;
}
.editing .cancelEdit{
    padding: 6px 16px;
    font-weight: 500;
    font-size: 0.7rem;
    border: 1px solid #ff4a02;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: white;
    color: #ff4a02;
}
.editing .updateEdit{
    padding: 6px 16px;
    font-weight: 500;
    font-size: 0.7rem;
    border: 1px solid #027FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: white;
    color: #027FFF;
}
.editNow{
    display: flex;
    gap: 10px;   
}
.editNow button{
    padding: 6px 16px;
    font-weight: 600;
    font-size: 0.7rem;
    border: 1px solid #ffcb08;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: white;
    color: #ffcb08;
}