.eachRemovableItem{
    display: flex;
    justify-content: space-between;
    padding: 10px 4%;

}
.eachRemovableItem:hover{
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.serviceName{
    font-weight: 600;
    color: #313131;
}
.servedByName{
    font-size: 0.7rem;
    display: flex;
    align-items: center;
}
.price{
    font-size: 0.8rem;
}
.addOnLabel{
    font-size: 0.7rem;
}
.buttonWrapper{
    display: flex;
    align-items: center;
}
.buttonContainer{
    font-weight: 700;
    font-size: 0.8rem;
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    background-color: black;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.buttonContainer button{
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    color: white;
}
.loaderBtn{
    overflow: hidden;
    width: 100px;
    border: 1px solid #dfdfdf;
    height: 24px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}   