.mainWrapper {
    width: 60%;
    height: 87svh;
    position: relative;    
}
.mainContainer{
    margin: 3.5svh 5%;
    height: calc(76svh - 2px);
    width: calc(86% - 2px);
    overflow-y: scroll;
    background-color: #FFFFFF2B;
    backdrop-filter: saturate(180%) blur(10px);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 2svh 2%;
    color: #dfdfdf;

}
.mainContent{
    height: calc(76svh - 2px);
    padding: 0px 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.responseMessageSection{
    font-size: 0.9rem;
    font-style: italic;
    color: red;
    text-align: right;
    width: 100%;
    font-weight: 600;
}

.eachInputSection{
    width: 100%;
    /* border: 1px solid red; */
}
.eachInputTitle{
    font-size: 0.9rem;
    font-weight: 600;    
}
.usernameBox{
    padding: 10px 2%;
    width: 96%;
    border: none;
    border-bottom: 1px solid #dfdfdf25;
    background-color:transparent;
    color: white;
}

.cardNumberContainer{
    background-color: rgb(117, 117, 117);
    display: flex;
    justify-content: space-between;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 10px 0px;
    overflow: hidden;

}
.cardNumberContainer input{
    padding: 0px;
    border: none;
    padding: 8px 8px;
    font-weight: 600;
    background-color: rgb(117, 117, 117);
    color: white;
}


.phoneWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.getDetailsBtnWrapper{
    display: flex;
    align-items: center;
}
.getDetailsBtn{
    padding: 8px 0px;
    width: 120px;
    /* background: #313131; */
    background: #000000;
    border: 1px solid #ffffff70;
    color: white;
    font-weight: 600;
    border-radius: 4px;
}
.shBtn{
    margin-bottom: 0px !important;
    width: 140px !important;
}
.genderRow{
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
}
.genderTitle{
    width: 20%;
    font-weight: 600;
    text-transform: uppercase;
}
.genderOptions{
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.genderBtn{
    width: 20%;
    padding: 4px 0px;
    border: 0.6px solid #dfdfdf;
    font-size: 0.7rem;
    font-weight: 600;
    background-color: #444444;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.genderBtnSelected{
    width: 20%;
    padding: 4px 0px;
    border: 1px solid #ffffff;
    font-size: 0.7rem;
    font-weight: 600;
    background-color: #00880b;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.userDetailsSection{
    padding: 20px 5%;
    width: 90%;
    background-color: #00000090;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.collectPaymentSection{
    width: 90%;
    margin: 10px 0px;
    padding: 20px 5%;
    background-color: black;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.amountRow{
    display: flex;
    justify-content: space-between;
    font: 1rem;
    color: #ffffff;
    font-weight: 600;
}
.gstRow{
    display: flex;
    justify-content: space-between;
    padding: 4px 10px 8px;
    font-size: 0.8rem;
    font-weight: 600;
}
.gstCaptionWrapper{
    display: flex;
    align-items: center;
}
.gstToggleWrapper{
    display: flex;
    align-items: center;
}
.gstAdd{

    padding: 6px 10px;
    font-weight: 600;
    margin: 0px 6px;
    font-size: 0.6rem;
    border: none;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    
}
.gstRemove{
    padding: 6px 10px;
    font-weight: 600;
    margin: 0px 6px;
    font-size: 0.6rem;
    border: none;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: red;
    color: white;
}

.gtRow{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dfdfdf;
    /* border-bottom: 1px solid #dfdfdf; */
    padding: 8px 0px;
    font-weight: 600;
}


.pmRow{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 0px 0px;
}
.pmTitle{
    width: 20%;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.8rem;
}
.pmBtns{
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.payModeBtn{
    padding: 5px 0px;
    font-size: 0.8rem;
    width: 100px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    background-color: #444444;
    border: 0.3px solid #c1c1c1;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.2px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.payModeBtnSelected{
    padding: 5px 0px;
    font-size: 0.8rem;
    width: 100px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    background-color: #3e860d;
    border: 0.3px solid #c1c1c1;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.2px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}



.submitBtnSection{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.submitBtnSection button{
    padding: 10px 0px;
    width: 180px;
    background-color: #FB651F;
    border: 1px solid #FB651F;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}


.nameLoader{
    padding: 10px 0px 0px;
    width: 200px;
}



.shimmerBtn{
    margin: 0px !important;
}