.mainWrapper{
    height: calc(93svh - 3px);
    width: 100%;
    display: flex;
    background-color: rgba(240, 248, 255, 0.419);
}
.sessionStoreWrapper{
    height: calc(91svh - 3px);
    width: calc(20% - 20px);
    padding: 1svh 10px;
    overflow-y: scroll;
    border-right: 1px solid #dfdfdf;
}
.initiateBookingBtn{
    margin: 0px 0px 10px 0px;
}
.initiateBookingBtn button{
    width: 100%;
    padding: 9px 15px;
    border: 1px solid #027FFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: white;
    background-color: #027FFF;
    font-weight: 600;
    font-size: 0.8rem;
}
.sessionDetailsWrapper{
    width: 85%;
}
.shortCutKeyWrapper{
    height: 60svh;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #81818190;
}
.shortCutKeyTitle{
    font-weight: 500;
    font-size: 1.1rem;
    padding: 10px 0px 20px;
}
.sckWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 0.8rem;
    font-weight: 500;    
}
.startedOnWrapper{
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0px 0px 4px;
}
.clientName{
    font-size: 14px;
    text-transform: uppercase;
}


.eachSessionWrapper{
    margin: 0px 0px 10px 0px;
    padding: 8px 3% 0px;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
}
.eachSessionWrapperSelected{
    margin: 0px 0px 10px 0px;
    padding: 8px 3% 0px;
    background-color: #027FFF10;
    border: 1px solid #027FFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
}
.startedOnWrapper{
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
}
.bookingIdentifierTxt{
    padding: 3px 8px;
    background-color: #027FFF34;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: #027FFF;
    border: 1px solid #027FFF34;
    font-weight: 500;
}
.timeStamp{
    text-transform: uppercase;
    font-size: 13px;
}
.clientCount{
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    width: 100%;
    gap: 10px;
    padding: 2px 0px 6px;
}
.eachCol{
    flex: 1;
    font-weight: 400;
    text-align: center;
    display: flex;
    gap: 10px;
}
.servicesSection{
    border-top: 1px solid #dfdfdf;
    padding: 5px 5px;
}
.eachService{
    font-size: 0.7rem;
    font-weight: 600;
    color: #00000075;
}
.actionsWrapper{
    display: flex;
    justify-content: flex-end;
    padding: 0px 0px 6px;
}
.deleteBookingBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 34px;
    border: 1px solid #dfdfdf;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}