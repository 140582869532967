.mainWrapper{
    padding: 10px 3%;
}
.mainTitle{
    color: #818181;
    font-weight: 500;
    font-size: 0.9rem;
}
.allMembersWrapper{
    border: 1px solid #dfdfdf50;
    padding: 0px 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 10px 0px;
    background-color: white;
    max-height: 50svh;
    overflow-y: scroll;
}
select{
    padding: 9px 6px !important;
}
.actionWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0px 0px;
    gap: 10px;
}
.cancelAction{
    padding: 8px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: transparent;
    font-weight: 500;
    background-color: white;
}
.completeAction{
    padding: 8px 20px;
    border: 1px solid #027fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: transparent;
    color: #027fff;
    font-weight: 500;
}
.addNewMemberWrapper{
    display: flex;
    border-top: 1px solid #dfdfdf;
    padding: 14px 0px;
    flex-direction: column;
}
.addNewBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.addNewBtn button{
    padding: 8px 28px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 500;
    font-size: 0.9rem;
    border: 1px solid #027fff;
    background-color: #027fff10;
    color: #027fff;
}
.fullName{
    display: flex;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    overflow: hidden;
}
.fullName select{
    max-width: 70px !important;
    min-width: 70px !important;
    border: none;
    border-right: 1px solid #dfdfdf;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    background-color: #dfdfdf50;
    font-weight: 600;
}
.fullName input{
    width: calc(240px - 12px);
    border: none;
    padding: 0px 6px;
}
.ageInputWrapper{
    display: flex;
}
.yearsWrapper{
    width: calc(160px - 12px);
}
.yearsWrapper select{
    min-width: calc(160px - 12px) !important;
    max-width: calc(160px - 12px) !important;
}
.monthsWrapper{
    width: calc(180px - 12px);
}
.monthsWrapper select{
    min-width: calc(180px - 12px) !important;
    max-width: calc(180px - 12px) !important;
}
.selectGenderWrapper{
    width: 150px;
}
.selectGenderWrapper select{
    min-width: calc(150px - 0px) !important;
    max-width: calc(150px - 0px) !important;
    border-right: 1px solid #dfdfdf;
}
.selectRelationWrapper{
    width: 150px;
}
.selectRelationWrapper select{
    min-width: calc(150px - 0px) !important;
    max-width: calc(150px - 0px) !important;
    border-right: none;
    text-transform: capitalize;
}