.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100svh;
    width: 100%;
    z-index: 4;
}
.mainContainer{
    position: relative;
    width: 100%;
    height: 100svh;
    background-color: #000000bf;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContent{
    background-color: white;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    height: 54svh;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}


.actionHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6svh;
    font-weight: 500;
    color: #818181;
    border-bottom: 1px solid #dfdfdf;
    background-color: #dfdfdf30;
}
.actionBody{
    height: 36svh;
    overflow-y: scroll;
    padding: 0px 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}
.bookingDate{
    text-align: center;
}
.actionFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 12svh;
}
.actionFooter .delete{
    padding: 12px 46px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    width: 150px;
}
.actionFooter .okay{
    padding: 12px 46px;
    border: 1px solid #027FFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: #027FFF;
    font-weight: 500;
    background-color: #027FFF10;
    width: 150px;
}

.eachRParam{
    padding: 0px 10px 10px;
}
.rTitle{
    font-size: 0.9rem;
    font-weight: 500;
}
.rValue{
    font-size: 0.8rem;
    font-weight: 500;
    color: #818181;
}