.mainWrapper{
    flex: 1;
}
.mainContainer{
    padding: 10px 2%;
}
.mainHeader{
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 8px 0px;
}
.title{
    font-weight: 500;
    color: #212121;
}
.subTitle{
    font-weight: 500;
    font-size: 0.8rem;
    color: #848484;
}
.genderIdentifierBtn{
    border: 1px solid #a5a5a5;
    background-color: #dfdfdf;
    padding: 4px 12px;
    margin-right: 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;
}
.genderIdentifierBtnSelected{
    border: 1px solid #008000;
    color: green;

    background-color: #00ff0045;
    padding: 4px 12px;
    margin-right: 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;
}