.eachBookingWrapper{
    margin: 10px 0px 10px 0px;
    background-color: white;
    padding: 0px 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.eachBookingContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.moreWrapper{
    right: 14px;
    top: 20px;
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #027fff30;
    
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}