.mainWrapper{
    height: 100svh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
}
.mainContainer{
    height: 60svh;
    width: 74%;
    position: relative;
    background-color: #000000bf;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20svh 13%;
}
.mainContent{
    background-color: white;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    padding: 5%;
    animation: appear 0.2s cubic-bezier(0.01, 1.24, 0.16, 0.98);
    -webkit-animation: appear 0.2s cubic-bezier(0.01, 1.24, 0.16, 0.98);
}

@keyframes appear {
    0% { transform: scale(0.1); -webkit-transform: scale(0.1); -moz-transform: scale(0.1); -ms-transform: scale(0.1); -o-transform: scale(0.1); }
    100% { transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
}
.title h2{
    padding: 0px;
    margin: 0px;
    text-align: center;
    font-weight: 600;
}
.subTitle{
    padding: 5px 0px;
    text-align: center;
}
.subTitle p{
    font-weight: 500;
    padding: 0px;
    margin: 0px 0px 10px;
    font-size: 0.7rem;
    color: #00000080;
}
.rowWrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.eachRow{
    display: flex;
    gap: 10px;
}
.eachRow input{
    padding: 10px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: 250px;
}
.eachRow select{
    width: calc(100% - 0px);
    padding: 10px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.createNowBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 0px;
}
.createNowBtn button{
    padding: 10px 44px;
    background-color: #027FFF;
    border: none;
    color: white;
    text-align: center;
    font-weight: 500;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}