.mainContainer{
    border-top: 1px solid #dfdfdf;
    width: 100%;
}
.categoryHeader{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dfdfdf50;
    padding: 10px 10px;
    width: calc(100% - 20px);
    cursor: pointer;
}
.categoryTitle{
    font-size: 1rem;
    font-weight: 500;
}
.categoryBody{
    width: 100%;
}
.addedServicesBody{
    min-height: 300px;
    border-bottom: 1px solid #dfdfdf;
}

.noResultsFound{
    text-align: center;
    color: #818181;
    padding: 30px 0px;
    font-weight: 500;
    font-size: 0.7rem;
}


.deleteCategoryWrapper{
    text-align: center;
    padding: 10px 0px;
    font-size: 0.7rem;
}
.deleteCategoryContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.deleteCategoryContainer button{
    padding: 6px 14px;
    border: 1px solid #dfdfdf80;
    font-size: 0.7rem;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    color: #818181;
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 600;
}
.deletingCategoryContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.deletingCategoryContainer button{
    padding: 6px 14px;
    border: 1px solid rgb(247, 62, 62);
    background-color: rgba(247, 62, 62);
    font-size: 0.7rem;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 600;
}