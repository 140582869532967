.mainWrapper{
    display: flex;
    flex: 1;
    background-color: #F9F9F9;
    height: 92svh;
    overflow-y: scroll;
}
.mainContainer{
    padding: 2svh 2%;
    flex: 1;
}
.upperHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hiname{
    font-weight: 500;
    font-size: 1.6rem;
    padding: 8px 0px;
}
.proName{
    font-size: 0.6rem;
    color: #848484;
    font-weight: 600;
}
.addClient{
    background-color: #007FFF;
    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 9px 15px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


.dateWrapper{
    display: flex;
    flex: 1;
    gap: 10px;
    justify-content: flex-end;
}
.dateRangeSelectorWrapperBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 6px 12px;
    font-size: 0.8rem;
    font-weight: 500;
    background-color: white;
    cursor: pointer;
}
.dateTitle{
    font-size: 0.6rem;
    padding: 0px 0px 4px;
    font-weight: 500;
}
.dateTitleEnd{
    font-size: 0.6rem;
    padding: 0px 0px 4px;
    text-align: right;
    font-weight: 500;
}
.dateInput{
    background-color: #EDEDED;
}
.dateInput input{
    background-color: transparent;
    border: 1px solid #dfdfdf;
    padding: 4px 18px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
}


.tilesWrapper{
    display: flex;
    gap: 20px;
    padding: 10px 0px;
}
.eachTileWrapper{
    background-color: white;
    flex: 1;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 2%;
}

.tValueWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tvalue{
    font-weight: 600;
    font-size: 1.4rem;

}
.tCaption{
    font-weight: 500;
    font-size: 0.7rem;
    padding: 10px 0px 0px;
    color: #898989;
}


.cardsWrapper{
    display: flex;
    gap: 20px;
    padding: 10px 0px;
    width: 100%;
}
.eachCardWrapper{
    background-color: white;
    flex: 1;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 2%;
}
.eachCardWrapperBig{
    background-color: white;
    flex: 2;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 2%;
}
.cardHeader{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.cardTitle{
    font-weight: 600;
    font-size: 1.1rem;
    color: #202020;
    display: flex;
    align-items: flex-start;
}


.topPerformerWrapper{
    background-color: white;
    flex: 2;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 2%;
}
.tasksWrapper{
    background-color: white;
    flex: 1;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 2%;
}

.tpwWrapper{
    display: flex;
    justify-content: space-between;
}
.tpwTitle{
    font-weight: 600;
    font-size: 1.1rem;
}
.tpwSubTitle{
    font-weight: 500;
    font-size: 0.6rem;
    padding: 10px 0px;
}
.tasksListWrapper{
    height: 40svh;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.allTask{
    height: 35svh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.eachTask{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    justify-content: space-between;
}
.eachTaskLeft{
    display: flex;
    align-items: center;
}
.checkIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.task{
    font-size: 0.8rem;
    font-weight: 500;
}
.deleteIcon{
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;
}
.addNewTask{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    border: 1px solid #dfdfdf;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    overflow: hidden;
}
.addTaskInput{
    flex: 7;
}
.addTaskInput input{
    width: calc(100% - 16px);
    padding: 5px 8px;
    border: none;
    font-size: 0.7rem;
    color: #202020;
    font-weight: 500;
}
.addTask{
    flex: 2;
}
.addTask button{
    background-color: #027FFF;
    color: white;
    border: none;
    font-weight: 600;
    font-size: 0.7rem;
    padding: 10px 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    width: 100%;
}