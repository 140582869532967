.mainHeader {
    height: 7vh;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: flex-end;
}
.selectACard{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0px 10px;
}
.selectACard select {
    border: 1px solid #dfdfdf;
    height: 4.4vh;
    min-width: 200px;
    background-color: #dfdfdf45;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
