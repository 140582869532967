.mainWrapper{
    width: 100%;
    height: 100svh;
}
.eachTransactionWrapper{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;
}
.clientName{
    flex: 1;
    padding: 8px 5px;
    text-transform: capitalize;
}
.mobile{
    flex: 1;
    text-align: left;
    padding: 8px 5px;
}
.gender{
    flex: 1;
    text-align: left;
    padding: 8px 5px;
}
.td{
    flex: 1;
    text-align: left;
    padding: 8px 5px;
}
.tt{
    flex: 1;
    text-align: left;
    padding: 8px 5px;
}
.amount{
    flex: 1;
    text-align: left;
    padding: 8px 5px;
}
.currentBalance{
    flex: 1;
    text-align: left;
    padding: 8px 5px;
}