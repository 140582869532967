.mainWrapper{
    height: calc(5svh - 1px);
    border-bottom: 1px solid #dfdfdf80;
    background-color: aliceblue;
    
    display: flex;
    align-items: center;
    padding: 0px 1%;
}
.tab{
    border: 1px solid #dfdfdf80;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 8px 0px 0px ;
    background-color: white;
    font-weight : 500;
    font-size: 0.7rem;
    padding: 4px 10px;
}
.activeTab{
    border: 1px solid green;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin: 0px 8px 0px 0px ;
    background-color: green;
    color: white;
    font-weight : 500;
    font-size: 0.7rem;
    padding: 4px 10px;
}
