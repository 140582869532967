.mainContainer{
    width: 100%;
    display: inline-flex;
    flex-direction: row;
}
.mainContent{
    display: flex;
    background-color: white;
    flex: 1;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}
.serviceListContainer{
    width: 60%;
    border-right: 1px solid #dfdfdf80;
    height: 74svh;
    overflow-y: scroll;
}
.addOnContainer{
    width: 40%;
}

.noCategoryWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.notFound{
    font-size: 0.8rem;
    font-weight: 500;
    padding: 20px 0px 10px;
}
.createNewBtn{
    padding: 10px 15px;
    background-color: #027FFF;
    border: 1px solid#027FFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: white;
    font-weight: 500;
    font-size: 0.8rem;
}
.addNewCategory{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #027FFF;
    margin: 20px 10%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
}
.addNewCategory{
    display: flex;
    flex: 1;
}
.enterCategoryInput{
    flex: 3;
}
.enterCategoryInput input{
    width: calc(100% - 18px);
    padding: 10px 9px;
    border: none;
}
.enterCategoryBtn{
    flex: 1;
}
.enterCategoryBtn button{
    width: 100%;
}
