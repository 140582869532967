.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100svh;
    width: 100%;
    background-color: #dfdfdfe7;
    z-index: 500;
}

.mainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40svh;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100svh;
}

.popUpBody{
    position: relative;
    height: 80svh;
    width: 50%;
    overflow: hidden;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border: 1px solid #027FFF;
}

.pubHeader{
    height: 7svh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #027FFF30;
    border-bottom: 1px solid #027fff;
    font-weight: 600;
}
.pubBody{
    height: 73svh;
    display: flex;
    justify-content: center;
    flex-direction: column;

}


.searchByHeader{
    display: flex;
    height: 6svh;
    border-bottom: 1px solid #dfdfdfe7;
    justify-content: space-between;
    align-items: center;
    padding: 0px 1%;
    background-color: #027FFF20;
}
.searchBar{
    width: 80%;
    height: 6svh;
    display: flex;
    align-items: center;
}
.searchBar input{
    width: 100%;
    height: 6svh;
    border: none;
    font-weight: 0.8rem;
    background-color: transparent;

    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.modeToggleBtn{
    width: 5%;
}
.modeToggleBtn button{
    background-color: transparent;
    border: none;
}

.addNewClient{
    margin: 20px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addNewClient button{
    padding: 9px 15px;
    background-color: #027FFF20;
    color: #027FFF;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    border: 1px solid #027FFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

}

.searchResultsWrapper{
    height: 67svh;
    padding: 0px 3%;
    overflow-y: scroll;
}


.noClientFound{
    display: flex;
    justify-content: center;
    font-weight: 500;
    align-items: center;
    height: 40svh;
    width: 100%;
    color: #818181;
    font-size: 0.7rem;
}