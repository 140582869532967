button{
    cursor: pointer;
}
.mainContainer{
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.addANewServiceBtn{
    text-align: center;
    padding: 7px 20px;
    margin: 10px 0px;
    cursor: pointer;
    background: #dfdfdf40;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-size: 12px;
}


.sampleServicesContainer{
    width: 100%;
}
.eachSampleService{
    display: inline-flex;
    width: 96%;
    padding: 9px 2%;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid #dfdfdf;
    
}
.essTitle{
    width: 55%;
}
.essDuration{
    width: 15%;
}
.essDuration select{
    width: calc(90% - 2px);
    padding: 5px 5%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    cursor: pointer;
}
.essPrice{
    width: 15%;
}
.essPrice input{
    width: 90%;
    padding: 5px 5%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    text-align: right;
}
.essBtn{
    width: 10%;
}
.essBtn button{
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
