.mainWrapper{
    display: flex;
    flex: 1;
}
.mainContainer{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 2% 3%;
}
.mainContent{
    display: flex;
    flex: 1;
    flex-direction: column;
}
.mainHeader{
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}
.mainTitle{
    font-weight: 500;
    font-size: 1.2rem;
    color: #212121;
}
.createNewStore button{
    background-color: #007FFF;
    color: white;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 7px 10px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border: none;
}

.chooseLocationWrapper{
    display: flex;
    flex: 4;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.chooseLocationTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.chooseLocationTitle h2{
    color: #202020;
    font-weight: 500;
}
.clSubTitle{
    font-size: 0.8rem;
}
.storesWrapper{
    display: flex;
    flex: 1;
}
.storesContainer{
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    gap: 18px;
    justify-content: center;
    align-items: center;
}
.eachStoreWrapper{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    padding: 26px 40px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
    border: 1px solid transparent;
}
.storeIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.storeTxt{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 0.8rem;
    flex-direction: column;
}

.eachStoreWrapper:hover{
    border: 1px solid #027FFF;
}
.storeAddress{
    font-size: 0.5rem;
    color: #848484;
}