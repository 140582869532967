.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2;
    width: 100%;
    height: 100svh;
    background-color: #77777738;
}
.mainContainer{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100svh;
}
.collectionWrapper{
    height: 100svh;
    width: 30%;
    padding: 0px 3%;
    background-color: whitesmoke;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.toBeCollectedWrapper{
    padding: 20px 0px;
    height: 8svh;
    border-bottom: 1px solid #dfdfdf;
}

.collectionWrapperBody{
    height: 92svh;
    overflow-y: scroll;
}
.toBeCollected{
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
}
.toBeCollectedCaption{
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: #313131;
}



.ledgerWrapper{
    padding: 10px 3%;
}
.ledgerHeader{
    width: 100%;
    font-weight: 600;
    color: #222222;
    padding: 4px 0px 10px;
}
.ledgerContainer{
    display: flex;
    justify-content: space-between;
}




.subscriptionsWrapper{
    padding: 10px 3%;
}
.subscriptionsHeader{
    width: 100%;
    font-weight: 600;
    color: #222222;
    padding: 4px 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.refreshBtn{
    background-color: transparent;
    border: none;
}
.eachCardWrapper{
    width: 97%;
    display: flex;
    justify-content: space-between;
    border: 1.1px solid rgb(0 0 0 / 6%);
    background-color: #FFFFFF;
    margin: 0px 0px 10px 0px;
    padding: 5px 1.5%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.colLeft{
    width: 75%;
    display: flex;
}
.colLeftFirst{
    width: calc(28% - 10px);
    padding: 5px 5px;
}
.colLeftSecond{
    width: 72%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cardNumber{
    font-weight: 600;
}
.expiryDate{
    font-size: 0.7rem;
    font-weight: 600;
    color: #9b9898;
}
.colCenter{
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.colRight{
    width: 10%;
}

.cardLabel{
    font-size: 1.0rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    background-color: #F5F8FA;
    padding: 7px 0px;
    width: 60px;
    height: 34px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

    font-size: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #525252;
}
.checkBoxBtn button{
    border: 1px solid transparent;
    background-color: transparent;
}


.currentPayHeader{
    width: 100%;
    font-weight: 600;
    color: #222222;
    padding: 4px 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.currentPayWrapper{
    padding: 10px 3%;
}
.eachCurrentPay{
    display: flex;
    justify-content: space-between;
}
.amountInputField input{
    border: none;
    border-bottom: 1px solid #dfdfdf;
    background-color: transparent;
    text-align: right;
    padding: 8px 0px;
}


.remainingWrapper{
    display: flex;
    border-top: 1px solid #dfdfdf;
    margin: 20px 0px;
    justify-content: space-between;
    padding: 10px 0px;
}
.rwLeft{
    width: 75%;
}
.rwRight{
    width: 25%;
}
.rAmtTxtExtra{
    font-size: 0.65rem;
    color: green;
    font-weight: 600;
}
.rAmtTxtShort{
    font-size: 0.65rem;
    color: #027fff;
    font-weight: 600;
}


.updateActionsWrapper{
    padding: 20px 0px 120px;
}
.uaInitiatorWrapper{
    display: flex;
    justify-content: flex-end;
}

.uaConfirmationWrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.summaryWrapper{
    width: 100%;
}
.summaryRows{
    font-size: 0.7rem;
}
.confirmationBtns{
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: #dfdfdf;
    width: 36%;
    padding: 20px 0px;
}
.summaryTitle{
    text-align: center;
    font-weight: 500;
    color: #313131;
}
.areYouSureTxt{
    text-align: center;
    padding: 20px 0px 15px;
    font-weight: 600;
}
.confirmBtnContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 30px 0px;
}
.loaderContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 30px 0px;
    flex-direction: column;
}
.actionBtnYes{
    padding: 8px;
    width: 140px;
    margin: 0px 20px;
    border: 1px solid rgb(3, 182, 3);
    background-color: rgba(199, 231, 199, 0.288);
    color: rgb(3, 182, 3);
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.actionBtnDeny{
    padding: 8px;
    width: 140px;
    margin: 0px 20px;

    border: 1px solid rgb(196, 0, 0);
    background-color: rgba(230, 195, 195, 0.295);
    color: rgb(196, 0, 0);
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.cancelUpdateCollectionInit{
    padding: 7px 20px;
    margin: 0px 20px 0px 0px;
    border: 1px solid #c1c1c1;
    background-color: #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.updateCollectionInit{
    padding: 7px 20px;
    background-color: #027fff;
    color: white;
    font-weight: 600;
    border: 1px solid #027fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.loaderIcon {
    height: 120px;
    width: 120px;
    margin: 10px 0px 50px;
}
.loaderIcon img{
    width: 100%;
}

.collectionDoneContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.collectionDoneIcon{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px 0px 40px;
}
.collectionDoneIcon img{
    width: 120px;
}


.sendInvoiceWrapper{
    padding: 10px 0px;
    text-align: center;
}
.sendInvoiceBtn{
    padding: 9px;
    width: 180px;
    border: 1.5px solid #027fff;
    background-color: #027fff10;
    color: #027fff;
    font-weight: 600;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.invoiceBeingShared{
    font-style: italic;
    text-align: center;
    padding: 9px;
    width: 200px;
    border: 1.5px solid transparent;
    /* border: none; */
    background-color: #027fff20;
    color: #027fff;
    font-weight: 600;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

    letter-spacing: 1.1px;
}
.invoiceSharedTxt{
    text-align: center;
    color: #222222;
}



.markAsCompletedWrapper{
    padding: 40px 0px 10px;
    text-align: center;
}
.markAsCompletedWrapper button{
    padding: 9px;
    width: 200px;
    background-color: #027fff20;
    color: #027fff;
    font-weight: 600;
    border: 1px solid #027fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.updatingStatusTxt{
    font-weight: 600;
}
.moreBtnWrapper{
    padding: 40px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.viewInvoice{
    padding: 10px 22px;
    background-color: #027fff;
    color: white;
    border: 1px solid #027fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
}
.goToHome{
    padding: 30px 0px;
    text-align: center;
    font-size: 0.7rem;
}
.poweredBy{
    text-align: center;
    margin: 100px 0px 10px;
    padding: 40px 0px 0px;
    font-weight: 400;
    font-size: 0.5rem;
    color: #525252;
}