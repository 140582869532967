.mainWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.headerWrapper{
    width: 100%;
    height: 6vh;
}
.bodyWrapper{
    display: flex;
    flex: 1;
}
.leftWrapper{
    width: 36%;
    height: 94vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 2%;
}
.rightWrapper{
    width: 60%;
    display: flex;
    flex-direction: column;
}


.avgRating{
    font-size: 5.4rem;
    font-weight: 600;
}