.mainWrapper{
    flex: 1;
}
.mainContainer{
    padding: 10px 2%;
}
.title{
    font-weight: 500;
    color: #212121;
}
.subTitle{
    font-weight: 500;
    font-size: 0.8rem;
    color: #848484;
}