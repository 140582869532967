.mainWrapper {
    background-color: aliceblue;
}

.mainContainer {
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bodyContainer {
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: space-between;
}



.leftWrapper {
    width: 70%;
    height: 100svh;
}

.titleWrapper {
    height: 6svh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5%;
}

.welcomeTxt {
    font-weight: 600;
}

.dateRangeSelectorWrapperBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #dfdfdf;
    padding: 5px 10px;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.contentWrapper {
    height: 94svh;
    width: 90%;
    padding: 0px 5%;
}

.summaryBlockWrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
    gap: 40px;
}

.summaryBlockWrapperUpper {
    display: flex;
    justify-content: space-between;
    flex: 2;
}

.summaryBlockWrapperTile {
    flex: 0.6;
    row-gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.eachOption {
    width: 20%;
    background-color: #D0E0E9;
    padding: 10px 1.25%;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.iconsRow {
    display: flex;
    justify-content: space-between;
}

.rupeeIcon {
    padding: 10px 0px;
    font-size: 28px;
    display: flex;
    align-items: center;
}

.optionCaption {
    padding: 10px 0px;
}

.eachTile {
    padding: 4px;
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    text-align: right;
    background-color: #D0E0E9;
}

.tileTitle {
    font-size: 0.7rem;
    font-weight: 500;
}

.rightWrapper {
    width: 30%;
    height: 100svh;
}


.allTransactionsWrapper {
    height: 60svh;
}

.allTransactionsContainer {
    height: 55svh;
    overflow-y: scroll;
}



@media only screen and (max-width: 600px) {
    .bodyContainer {
        flex-direction: column-reverse;
    }

    .leftWrapper {
        flex: 1;
        width: 100%;
    }

    .summaryBlockWrapper {
        flex-direction: column;
        gap: 20px;
    }

    .summaryBlockWrapperUpper {
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;
    }

    .eachOption {
        width: 46.5%;
        background-color: #D0E0E9;
        border-radius: 8px;
        padding: 1%;
    }
}