.mainWrapper{
    display: flex;
    flex: 1;
    background-color: #FAFAFB;
}
.close{
    flex: 4;
    animation: close 0.2s forwards;
    -webkit-animation: close 0.2s forwards;
    overflow: hidden;
    height: 100svh;
    border-right: 1px solid #dfdfdf;
}
.open{
    flex: 1;
    animation: open 0.1s forwards;
    -webkit-animation: open 0.1s forwards;
    overflow: hidden;
    height: 100svh;
    border-right: 1px solid #dfdfdf;
}
@keyframes close {
    0% { flex: 1;}
    100% { flex: 4;}
}
@keyframes open {
    0% { flex: 4;}
    100% { flex: 1;}
}
.rightCol{
    flex: 22;
}
.upperHeaderWrapper{
    height: 7vh;
    border-bottom: 1px solid #dfdfdf;
}