.mainWrapper{
    flex: 1;
}
.mainContainer{
    padding: 10px 2%;
}
.mainHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
}
.title{
    font-weight: 500;
    color: #212121;
}
.subTitle{
    font-weight: 500;
    font-size: 0.8rem;
    color: #848484;
    width: 80%;
    display: flex;
    align-items: center;
}
.addNew a{
    padding: 9px 15px;
    font-size: 0.8rem;
    font-weight: 600;
    border: 1px solid #007fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #007fff;
    color: white;
}

.mainBody{
    flex: 1;
}

.tableWrapper{
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    height: 76svh;
    border: 1px solid #007fff60;
    overflow: hidden;
}
.tableHeader{
    background-color: #007fff30;
    height: 5svh;
    border-bottom: 1px solid #007fff60;
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 0.8rem;
    font-weight: 500;
    gap: 10px;
    color: #007fff;
    text-transform: uppercase;

}
.tableData{
    height: 71vh;
    overflow-y: scroll;
}
.tableTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5svh;
    padding: 0px 0% 5px;
    width: 100%;
}
.tableSearch input{
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 9px 8px;
    font-size: 0.8rem;
    background-color: #dfdfdf40;
    width: 300px;
}
.resultsCount{
    font-size: 0.7rem;
}

.eachTableRow{
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 0.8rem;
    font-weight: 500;
    gap: 10px;
    color: #848484;
    border-bottom: 1px solid #dfdfdf50;
}
.eachTableRow:hover{
    background-color: #007fff20;
}
.tCount{
    flex: 0.4;
    text-align: center;
}
.eachTableRow .tCount{
    flex: 0.4;
    text-align: center;
    padding: 10px 0px;    
}
.tFullname{
    flex: 2;
    text-transform: uppercase;
}
.tCode{
    flex: 0.4;
}
.tPhone{
    flex: 1;
}
.tEmail{
    width: 20%;
    padding: 0px 6px;
}
.tBirthdate{
    flex: 0.8;
}
.tAnniversary{
    flex: 1;
}
.tAction{
    /* flex: 2; */
    width: 10%;
    padding: 4px 0px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.deleteBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
    height: 36px;
    width: 36px;
    background-color: rgba(247, 171, 171, 0.21);
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.viewBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #1d7af4;
    height: 36px;
    width: 36px;
    background-color: #1d7af430;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}