.tableWrapper{
    padding: 0px 2%;
}
.countTitle{
    padding: 8px 10px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.addNewTrans button{
    padding: 8px 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-size: 0.8rem;
    border: 1px solid #027aff;
    background-color: #027aff20;
    color: #027aff;
    font-weight: 500;
}
.transactionWrapper{
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    height: 66svh;
    overflow: hidden;
}
.eachRowWrapperHeader{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    align-items: center;
    width: 100%;
    background-color: aliceblue;
    font-weight: 500;
    color: #818181;
    font-size: 0.8rem;
    height: 6svh;
}
.transactionsWrapper{
    background-color: white;
    height: 60svh;
    overflow-y: scroll;
}
.eachRowWrapper{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    align-items: center;
    width: 100%;
    height: 7svh;
}

.iconWrapperHolder{
    width: 5%;
    padding: 0px 10px;
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7svh;
}
.dateWrapper{
    width: 16%;
    padding: 0px 10px;
    border-right: 1px solid #dfdfdf;
    height: 7svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.currentBalance{
    width: 14%;
    border-right: 1px solid #dfdfdf;
    height: 7svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
}
.ago{
    font-size: 0.6rem;
    font-weight: 600;
    color: #818181;
    width: 18%;
    padding: 0px 10px;
    height: 7svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #dfdfdf;
}
.action{
    width: 60px;
    padding: 8px 0px;
    text-align: center;
}
.print{
    text-align: center;
    cursor: pointer;
}
.transactionDate{
    font-size: 0.8rem;
    font-weight: 500;
    height: 7svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.transactionTime{
    font-size: 0.7rem;
    font-weight: 500;
    color: #818181;
    height: 7svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.amount{
    width: 10%;
    padding: 0px 1%;
    border-right: 1px solid #dfdfdf;
    height: 7svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
}
.transactionType{
    width: 20%;
    padding: 0px 1%;
    border-right: 1px solid #dfdfdf;
    height: 7svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.currentBalance{
    padding: 0px 1%;
    height: 7svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.iconWrapper{
    height: 32px;
    width: 32px;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.thatsAll{
    text-align: center;
    padding: 30px 10px;
    font-weight: 500;
    font-size: 0.7rem;
    color: #818181;
}