.mainContainer{
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.leftBody{
    width: 20%;
}
.centerBody{
    width: calc(80% - 2px);
    border-right: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    height: 100vh;
    overflow-y: scroll;
}

