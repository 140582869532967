.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2;
    width: 100%;
    height: 100svh;
    background-color: #00000080;

    
}
.mainContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100svh;
}
.mainSelectorBox{
    height: 60svh;
    width: 40%;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
    border: 1px solid #027FFF;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;

}


.eachAddOnWrapper{
    display: flex;
    justify-content: space-between;
    padding: 10px 3%;
    cursor: pointer;
}

.mainSelectorHeader{
    height: calc(8svh - 1px);
    display: flex;
    align-items: center;
    padding: 0px 3%;
    font-weight: 600;
    border-bottom: 1px solid #027fff;
    background-color: #027fff30;
    color: black;
    text-transform: capitalize;
}
.mainSelectorBody{
    height: 44svh;
    overflow-y: scroll;
}
.mainSelectorFooter{
    height: 8svh;
    background-color: #027fff10;
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 0px 2.5%;
    border-top: 1px solid #027FFF;
}


.leftSection{
    width: 84%;
}
.centerSection{
    width: 10%;
    text-align: right;
    padding: 0px 3% 0px 0px;
}
.rightSection{
    width: 3%;
}

.pricesWrapper{
    display: flex;
    align-items: baseline;
}
.addOnOrgPrice{
    font-size: 0.6rem;
}
.addOnDisPrice{
    font-size: 1rem;
    font-weight: 600;
}
.top{
    padding: 0px 10px 0px 0px;
    font-size: 0.8rem;
    font-weight: 500;
    color: #027FFFad;
    text-decoration: line-through;
}
.tdp{
    font-size: 1.4rem;
    font-weight: 600;
    color: #027FFF;
}

.addToCartBtnWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.addToCartBtnWrapper button{
    padding: 9px 15px;
    width: 140px;
    border: 1px solid #027FFF;
    font-weight: 600;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #027FFF;
    color: white;
    font-size: 0.8rem;
}
.noBottomMargin{
    margin-bottom: 0px !important;
}