.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
}
.openMenuBtn{
    opacity: 0.2;
    margin: 10px 5px;
    height: 40px;
    width: 40px;
    border: none;
    /* border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.openMenuBtn:hover{
    opacity: 1;
}



.filtersPanelWrapper{
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: fixed;
    top: 0px;
    left: -100%;
    background-color: #000000a8;
    display: inline-flex;
    justify-content: flex-end;
}
.mainLeft{
    background-color: #f1f1f1;
    height: 100svh;
    width: 22.5%;
    position: absolute;
    left: 0px;
    /* top: 0px; */
    /* z-index: 100000; */
}
.menuWrapper{
    position: relative;
    width: 100%;
    height: 100svh;
    /* display: flex; */
}


.hamburger{
    height: 5svh;
    display: flex;
    align-items: center;
}
.hamburger button{
    background-color: white;
    height: 30px;
    width: 40px;
}
.closeBtn{
    height: 34px !important;
    width: 34px !important;
    border: none;
    background-color: #F1F1F1 !important;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
.menuBodyWrapper{
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    height: calc(88svh - 2px);
    overflow-y: scroll;
    padding: 0px 0px;
    background-color: white;
}
.eachOption{
    width: calc(94% - 0px);
    padding: 10px 3%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menuIcons{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.menuTxt{
    width: 90%;
    display: flex;
    align-items: center;
}
.footerWrapper{
    height: 7svh;
    display: flex;
    align-items: center;
}
.footerWrapper button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 8px;
    width: 100px;
    letter-spacing: 1px;
    font-weight: 600;
}
.footerWrapper button:hover{
    color: #da1884;
}


@media only screen and (max-width: 600px) {
    .mainLeft{
        width: 70%;
    }
    .menuWrapper{
        width: 100%;
    }
}