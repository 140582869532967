.mainWrapper{
    padding: 20px 2%;
}
.mainContainer{
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border: 1px solid #027fff60;
    overflow: hidden;
}

.mainHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 5px;
    /* box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px; */
    height: 5svh;

}
.optionWrapper{
    display: flex;
    gap: 6px;
}
.mainTableHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 0px;
    height: 5svh;
    border-bottom: 1px solid #dfdfdf;
    font-weight: 500;
    background-color: #007fff30;
    border-bottom: 1px solid #027fff60;
    color: #027fff;
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
    gap: 10px;
}
.itemsWrapper{
    padding: 0px 0px;
    height: 75svh;
    overflow-y: scroll;
}
.eachItemWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    font-size: 0.8rem;
    border-bottom: 1px solid #81818130;
    min-height: 5vh;
    gap: 10px;
}
.eachItemWrapper:hover{
    background-color: #027fff24;
}
.collectionBtn{
    padding: 9px 15px;
    font-size: 0.8rem;
    border: 1px solid #007fff30;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #007fff30;
    color: #027fff;
    font-weight: 600;
}
.AddNewExpenseBtn{
    padding: 9px 15px;
    background-color: #027fff;
    color: white;
    font-weight: 600;
    border: 1px solid #027fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-size: 0.8rem;
}

.sn{
    width: 3%;
}
.category{
    width: 20%;
    text-transform: uppercase;
    font-size: 13px;
}
.subCategory{
    width: 18%;
    text-transform: uppercase;
    font-size: 13px;
}
.expenseDetails{
    width: 40%;
    padding: 6px 0px;
}
.expenseName{
    font-size: 0.8rem;
    text-transform: uppercase;
}
.expenseNotes{
    font-size: 12px;
    color: #818181;
}
.price{
    width: 10%;
}
.date{
    width: 10%;
}
.time{
    width: 10%;
    text-align: right;
    font-size: 0.8rem;
    padding: 0px 10px 0px 0px;
}



.dateWrapper{
    display: flex;
    flex: 1;
    gap: 10px;
    justify-content: flex-end;
}
.dateRangeSelectorWrapperBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 6px 12px;
    font-size: 0.8rem;
    font-weight: 500;
    background-color: white;
    cursor: pointer;
}
.dateTitle{
    font-size: 0.6rem;
    padding: 0px 0px 4px;
    font-weight: 500;
}
.dateTitleEnd{
    font-size: 0.6rem;
    padding: 0px 0px 4px;
    text-align: right;
    font-weight: 500;
}
.dateInput{
    background-color: #EDEDED;
}
.dateInput input{
    background-color: transparent;
    border: 1px solid #dfdfdf;
    padding: 4px 18px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
}
