.mainWrapper{
    width: 100%;
    height: 100svh;
    z-index: 3;
    background-color: #31313140;
    position: fixed;
    top: 0px;
    left: 0px;
}
.mainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40svh;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100svh;
}

.popUpBody{
    position: relative;
    height: 60svh;
    width: 40%;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.pubHeader{
    height: 7svh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pubBody{
    height: 43svh;
    display: flex;
    justify-content: center;
    flex-direction: column;

}
.pubFooter{
    height: 10svh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pubFooter button{
    padding: 9px 15px;
    background-color: #027FFF;
    color: white;
    font-weight: 600;
    border: 1px solid #027FFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


.eachGenderOption{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 7%;
    margin: 10px 0px;
}
.genderCaption{
    display: flex;
    align-items: center;
}
.genderCaptionTxt{
    padding: 0px 0px 0px 10px;
    font-weight: 600;
}
.eachGenderCounter{
    display: flex;
    align-items: center;
}
.eachGenderCounter button{
    background-color: transparent;
    border: none;
}
.counterCount{
    font-weight: 700;
    font-size: 1.3rem;
    width: 70px;
    text-align: center;
}