.mainWrapper{
    height: 65svh;
    overflow-y: scroll;
    background-color: white;
    padding: 0px 0%;
}
.eachServiceWrapper{
    padding: 10px 10px;
    margin: 1px 10px;
    display: flex;
    border-bottom: 1px solid #dfdfdf;
}
.leftCol{
    width: 60%;
}
.rightCol{
    width: 40%;
}
.serviceNameRow{
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}
.serviceName{
    font-weight: 600;
    font-size: 13px;
    width: 40%;
}
.categoryName{
    text-transform: uppercase;
    font-size: 13px;
    width: 15%;
}


.priceWrapper{
    display: flex;
    align-items: flex-end;
}
.baseDiscountedPrice{
    font-size: 13px;
    font-weight: 600;
    padding: 0px 0px 0px 5px;
}
.basePrice{
    font-size: 0.8rem;
    font-weight: 600;
    color: #00000070;
}


.addOnWrapper{
    display: flex;
    justify-content: space-between;
}
.addOnLabel{
    font-size: 0.7rem;
    font-weight: 600;
    color: #00000070;
}
.addOnPriceWrapper{
    display: flex;
    color: #00000060;
    align-items: flex-end;
}
.addOnOrgPrice{
    font-size: 0.7rem;
}
.addOnDisPrice{
    font-size: 0.7rem;
}



.eachServiceFinalPriceWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 5px 0px 0px;
}
.totalOrgPrice{
    font-size: 0.8rem;
    font-weight: 600;
    color: #00000070;
}
.totalFinalPrice{
    font-size: 13px;
    font-weight: 600;
    padding: 0px 0px 0px 10px;
}

.whoWrapper{
    display: flex;
    justify-content: space-between;
    padding: 0px 5%;

}
.whoWrapperEachCol{
    width: 44%;
    margin: 0px 3%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 3%;
    align-items: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #ffffff;
    flex-direction: column;
}
.staffIcon{
    background-color: transparent;
}
.colTitle{
    font-size: 0.5rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: #00000060;
}
.whoName{
    text-align: center;
    font-weight: 600;
    padding: 0px 0px 0px;
    font-size: 13px;
    text-transform: uppercase;
}


.thatsAll{
    height: 15svh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    font-size: 13px;
}

.bottomTotalWrap{
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: #1f7af4;
    color: white;
    width: 85%;
    padding: 10px;
    margin: 10px 6.5% 10px 8.5%;
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid white; */
}

.finalPriceTagsWrapper{
    display: flex;
    align-items: flex-end;
}
.orgPriceTotal{
    font-size: 0.8rem;
    font-weight: 600;
    color: #dfdfdf;
}
.disPriceTotal{
    font-size: 1.8rem;
    font-weight: 600;
    padding: 0px 0px 0px 20px;
}