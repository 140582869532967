.mainWrapper{
    width: 100%;
    margin: 0px 0px 20px;
}
.stylistListWrapper{
    display: flex;
    width: 94%;
    overflow-x: scroll;
    flex-shrink: 0;
    padding: 0px 3%;
}

.title{
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
    color: #030303;
    padding: 10px 0px 10px 3%;
}

.eachWBox{
    min-width: 80px;
    height: 80px;
    background-color: #fff;
    margin: 8px 10px 18px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #027fff10;

    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.eachWBoxSelected{
    min-width: 80px;
    height: 80px;
    background-color: #027fff30;
    margin: 8px 10px 18px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #027fff;

    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


.staffProfilePic img{
    width: 36px;
    height: 36px;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
}
.name{
    font-size: 0.7rem;
    font-weight: 600;
    text-align: center;
    padding: 4px 4px;
}