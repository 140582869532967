.mainWrapper{
    background-color: aliceblue;
}
.mainContainer{
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mainBodyWrapper{
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: space-between;
}
.leftCol{
    width: calc(75% - 1px);
    height: 100svh;
    border-right: 1px solid #dfdfdf;
}
.headerWrapper{
    width: 100%;
    height: calc(10svh - 1px);
    border-bottom: 1px solid #dfdfdf;
}
.upperHeaderWrapper{
    background-color: white;
    width: 95%;
    /* border-bottom: 1px solid #dfdfdf; */
    height: calc(4svh - 1px);
    display: flex;
    padding: 0px 0px 0px 5%;
    align-items: center;
    font-weight: 600;
    color: #3a3a3a;
}
.lowerHeaderWrapper{
    width: 100%;
    display: flex;
    background-color: white;
    justify-content: space-between;
    height: 6svh;
}
.inputSearchWrapper{
    width: 32%;
    padding: 0px 3% 0px 5%;
}
.inputSearchWrapper input{
    padding: 8px 2%;
    width: calc(96% - 2px);
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #dfdfdf80;
}
.optionsWrapper{
    width: 60%;
    display: flex;
    justify-content: space-between;
}
.eachFilterWrapper{
    display: flex;
    flex-direction: column;
    width: 30%;
}
.eachFilterCaption{
    font-size: 0.7rem;
    font-weight: 700;
    padding: 0px 0px 0px 5px;
    color: #919191;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.eachFilterOption select{
    padding: 2px 0px;
    font-size: 0.8rem;
    width: 100%;
    border: 1px solid transparent;
    /* border: 1px solid chartreuse; */
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 600;
    color: #3a3a3a;
    cursor: pointer;
}
.eachFilterOption select:focus{
    outline: none;
}

.bodyWrapper{
    height: 90svh;
    display: flex;
}
.verticalColWrapper{
    width: 5%;
    height: 90svh;
    border-right: 1px solid #dfdfdf;
    background-color: white;
}
.verticalRightColWrapper{
    width: calc(95% - 1px);
    height: 90svh;
}
.rightCol{
    width: 25%;
    height: 100svh;
}

.addNewMemberWrapper a{
    font-size: 0.8rem;
    padding: 8px 14px;
    background-color: #FB651F;
    color: white;
    border: 1px solid #FB651F;
    font-weight: 600;
}







.tableWrapper{
    width: 92%;
    height: 82svh;
    padding: 4svh 4%;
}
.tableContainer{
    /* border: 1px solid #dfdfdfa4; */
    /* height: calc(82svh - 2px); */
    height: 82svh;
    /* background-color: #bebebe; */
    background-color: #bebebe24;
}
.tableHeader{
    width: 100%;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px);
    border-bottom: 1px solid #dfdfdf;
    background-color: #3a3a3a;
    color: white;
}
.tableBody{
    height: 76svh;
    overflow-y: scroll;
}
.eachTableRow{
    height: calc(6svh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
}

.indexCol{
    width: calc(4% - 1px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(6svh - 1px);
}
.cardNumberCol{
    width: calc(25% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(6svh - 1px);
    padding: 0px 10px;
}
.cardNumberTxt{
    font-weight: 700;
}
.clientName{
    font-size: 0.7rem;
}
.membershipType{
    width: calc(10% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px);
    padding: 0px 10px;
}
.servicesWorthCol{
    width: calc(10% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px);
    padding: 0px 10px;
}
.paidAmountCol{
    width: calc(10% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px);
    padding: 0px 10px;
}
.activatedOnCol{
    width: calc(18% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px);
    padding: 0px 10px;
}
.actionCol{
    width: calc(14% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px); 
    padding: 0px 10px;  
}

.noCardMembersFound{
    height: 40svh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.expiringIn{
    font-size: 0.7rem;
    color: red;
    font-weight: 600;
}