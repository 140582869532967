.mainWrapper{
    background-color: aliceblue;
    height: 94vh;
    display: flex;
    justify-content: center;
}
.mainContainer{
    display: flex;
    align-items: center;
    width: 100%;
}
.starWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 30%;
}
.starContainer{
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.bigGenderIcon{
    width: 180px;
    height: 180px;
}
.bigGenderIcon img{
    height: 100%;
}
.servedByNameBig{
    font-size: 1.4rem;
    font-weight: 500;
    padding: 5px 0px;
    text-transform: uppercase;
}
.positionIconBig{
    width: 120px;
    height: 120px;
}
.positionIconBig img{
    width: 100%;
}
.runnerWrapper{
    display: flex;
    width: 22%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.indiWrapper{
    display: flex;
    flex-wrap: wrap;
    width: 48%;
}


.eachRunnerWrapper{
    height: 170px;
    width: 170px;
    background-color: white;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.servedByNameRunner{
    padding: 10px 0px 3px;
    font-weight: 500;
    text-align: center;
}
.eachIndiWrapper{
    height: 150px;
    width: 150px;
    background-color: white;
    margin: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.smallGenderIcon{
    height: 80px;
    width: 80px;
}
.smallGenderIcon img{
    width: 100%;
}
.servedByNameSmall{
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
    padding: 10px 0px 4px;
}
.labelWrapper{
    width: 100%;
}

.valueWrapper{
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;    
}