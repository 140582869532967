.mainWrapper{
    padding: 4px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    margin: 5px 2px;
}
.mainWrapper:hover{
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.modifyQuantity{
    margin: 5px 0px 0px 0px;
    display: flex;
    align-items: center;
    width: 65px;
    justify-content: space-between;
    color: #027FFF;
    padding: 5px 8px;
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;


    background-color: #027FFF20;
    border: 1.2px solid #027FFF;
}
.modifyQuantity button{
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #027FFF;
    border: none;
    padding: 0px 5px;
    
}
.addToCartBtn{
    display: flex;
    justify-content: flex-end;
}
.addToCartBtn button{
    margin: 5px 0px 0px 0px;
    background-color: #027FFF20;
    border: 1.2px solid #027FFF;
    padding: 4px 15px;
    font-size: 0.7rem;
    font-weight: 600;
    color: #027FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.serviceName{
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}
.serviceDuration{
    font-size: 0.7rem;
    font-weight: 500;
}
.description{
    font-size: 0.7rem;
    font-weight: 500;
    color: #818181;
}
.siRightWrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.serviceAmout{
    display: flex;
    justify-content: flex-end;
    /* flex-direction: column; */
    align-items: flex-end;
    text-align: right;
}


.cutOrgPrice {
    text-decoration-line: line-through;
    -moz-text-decoration-line: line-through;
    font-size: 0.8em;
    color: red;
    animation: reduceFontSize 0.2s forwards;
    text-align: right;
    -webkit-animation: reduceFontSize 0.2s forwards;
}
.basePrice{
    text-align: right;
}
.visualMode{
    display: flex;
    align-items: center;
    gap: 5px;
    height: 20px;
    padding: 5px 0px;
    font-size: 14px;
    font-weight: 500;
}
.editingMode{
    display: flex;
    align-items: center;
    gap: 5px;
    height: 20px;
    padding: 5px 0px;
}
.basePrice input{
    width: 50px;
    padding: 6px 10px;
    border: none;
    background: #dfdfdf;
    border-radius: 6px;
    text-align: right;
    font-weight: 500;
}
.discountedPrice {
    padding: 0px 0px 0px 8px;
    font-weight: 600;
    font-size: 1.2em;
    color: green;
    animation: increaseFontSize 0.2s forwards;
}

@keyframes reduceFontSize {
    0% { font-size: 1.2em; }
    100% { font-size: 0.8em; }
}

@keyframes increaseFontSize {
    0% { font-size: 1em; }
    100% { font-size: 1.2em; }
}
