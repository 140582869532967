.mainWrapper{
    /*border: 1px solid #dfdfdf;
    */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    margin: 2px 0px 10px 2px;
    width: calc(100% - 4px);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.clientName{
    padding: 8px 1%;
    font-weight: 600;
    display: flex;
}
.additionalClient{
    font-weight: 600;
    font-size: 0.7rem;
    padding: 0px 0px 0px 4px;
}


.priceSection{
    padding: 5px 3%;
}
.totalServicesPrice{
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 600;
    color: #6b6b6b;
}
.eachPriceRow{
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    color: #979797;
    font-weight: 600;
}
.allDiscountsWrapper{
    padding: 8px 0px;
}
.allDiscountsBody{
    padding: 0px 10px 5px;
}
.totalDiscountRow{
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    /* color: #1f1f1f; */
    color: #6b6b6b;
    font-weight: 600;
}
.finalPayRow{
    border-top: 1px solid #dfdfdf;
    padding: 5px 0px;
    margin: 5px 0px 0px;
    font-size: 1.4rem;
    font-weight: 600;
}
.finalPrice{
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    /* color: #979797; */
    color: #3d3d3d;
    font-weight: 600;
}





.paymentWrapper{
    display: flex;
    padding: 10px;
}
.eachPayMode{
    width: 10%;
    /* border: 1px solid #dfdfdf; */
    padding: 4px 1%;
    margin: 0px 1% 0px 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.eachPayModeCaption{
    font-size: 0.6rem;
    font-weight: 600;
    color: #646464;
}





.ratingSection{
    padding: 10px 3%;
    text-align: center;
    font-size: 0.7rem;
}