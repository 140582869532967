.mainWrapper{
    height: 89svh;
    display: flex;
    
}

.summaryWrapper{
    height: 89svh;
    width: 30%;
}

