.masterContainer{
    height: calc(93vh - 3px);
    overflow-y: scroll;
}
.mainWrapper {
    padding: 20px;
}
.crTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 10px;
}
.toggleBtn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.includeExpenses{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #027FFF20;
    background-color: #027FFF20;
    gap: 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 3px 10px;
}
.downloadPdfBtn{
    background-color: #027FFF20;
    border: 1px solid #027FFF;
    color: #027FFF;
    margin: 4px;
    padding: 4px 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.header {
    text-align: center;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 24px;
    margin-bottom: 5px;
}

.header p {
    font-size: 14px;
    margin: 2px 0;
    color: #333;
}

.graphsWrapper{
    width: 100%;
    gap: 10px;
    display: flex;
    justify-content: space-between;
}
.graphContainer{
    flex: 1;
    border: 1px solid #dfdfdf;
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: white;
    height: 340px;
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    object-fit: cover;
}
.patientDetails {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

.patientDetails h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

.detailsRow {
    display: flex;
    justify-content: space-between;
    padding: 7px 4px;
    /* border-bottom: 1px solid #f0f0f0; */
    font-size: 11px;
    background-color: #EEF2F6;
    border: 1px solid #D4D5D5;
    font-weight: 500;
    text-transform: uppercase;
}

.detailsRow span {
    text-align: left;
}
.cartItemWrapper{
    padding: 6px 0px;
}
.eachCartItem{
    display: flex;
    gap: 30px;
    font-size: 9px;
    padding: 4px 14.5%;
}
.eachCartItem span{
    flex: 1;
    text-align: left;
}
.serviceName{
    text-transform: uppercase;
}
.categoryName{
    text-transform: uppercase;
}
.summarySection {
    margin-top: 20px;
    padding-top: 10px;
}

.summaryRow {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-weight: bold;
    color: #555;
}
.borderTop{
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 12px 0px;
}
.dangerTxt{
    color: red;
}
.tableWrapper{
    font-size: 11px;
    padding: 20px 0px 0px;
}

.leftTableWrapper{
    border: 1.5px solid #027FFF;
    width: 30%;
}
.eachRowWrapper{
    display: flex;
    gap: 10px;
}

.tableWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
.rightTableWrapper{
    border: 1.5px solid #027FFF;
    width: 25%;
}
.eachRowWrapper{
    height: 4svh;
    display: flex;
    align-items: center;
    padding: 0px 2%;
    justify-content: space-between;
}
.erwHeader{
    font-weight: 500;
    border-bottom: 1px solid #027FFF;
}
.erwOnly{
    font-weight: 500;
}
.netCollection{
    border-top: 1.5px solid #027FFF;
    background-color: #027FFF30;
    font-weight: 500;
    font-size: 13px;
}
.eachTableTotal{
    font-size: 13px;
}



.dateWrapper{
    display: flex;
    flex: 1;
    gap: 10px;
    justify-content: flex-end;
}
.dateRangeSelectorWrapperBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 6px 12px;
    font-size: 0.8rem;
    font-weight: 500;
    background-color: white;
    cursor: pointer;
}
.dateTitle{
    font-size: 0.6rem;
    padding: 0px 0px 4px;
    font-weight: 500;
}
.dateTitleEnd{
    font-size: 0.6rem;
    padding: 0px 0px 4px;
    text-align: right;
    font-weight: 500;
}
.dateInput{
    background-color: #EDEDED;
}
.dateInput input{
    background-color: transparent;
    border: 1px solid #dfdfdf;
    padding: 4px 18px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
}


.positive{
    color: green;
}
.negative{
    color: red;
}

.id{
    flex: 1;
}
.name{
    flex: 4;
}
.gender{
    flex: 2;
}
.phone{
    flex: 2;
}
.amount{
    flex: 2;
}

.date{
    flex: 2;
}
.category{
    flex: 3;
}
.subCategory{
    flex: 4;
}
.time{
    flex: 2;
}
.expenseName{
    flex: 5;
}
.expenseNotes{
    flex: 6;
}



.endOfTheReport{
    font-size: 0.8rem;
    text-align: center;
    font-weight: 500;
    color: #818181;
    padding: 40px 0px 20px;
}