.eachCardDetailsBigWrapper {
    width: 100%;
    display: flex;
    height: 87svh;
    /* background-image: linear-gradient(180deg, white, white, white, white, transparent); */
    background-color: #393A3B;
}

.cardContainer {
    width: 40%;
    /* border-right: 1px solid #dfdfdf; */
    height: 87svh;

}

.cardDescriptionWrapper {
    padding: 4px 10%;
}
.saleableLine{
    /* color: #FB651F; */
    color: white;
    font-size: 0.8rem;

}
.saleableLine h3{
    font-weight: 500;
    display: flex;
    align-items: center;
}

.actionBtnWrapper {
    padding: 4px 10%;
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.actionBtnWrapper button {
    padding: 12px 0px;
    width: 170px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border: 0.6px solid rgba(255, 255, 255, 0.562);
}

.shareBtn {
    background-color: #1e2027;
    margin: 0px 10px 0px 0px;
    color: white;
    border: 1px solid #24262c;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 600;
}

.buyBtn {
    background-color: #083CFF;
    border: 1px solid #083CFF;
    margin: 0px 10px 0px 10px;
    color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 600;
}

.cardDesignWrapper {
    padding: 140px 80px;
}

.cardDesign {
    color: white;
    height: 207px;
    width: 380px;


    transform: rotate3d(1.3, -2, 5, 70deg);
    -webkit-transform: rotate3d(1.3, -2, 5, 70deg);
    -moz-transform: rotate3d(1.3, -2, 5, 70deg);
    -ms-transform: rotate3d(1.3, -2, 5, 70deg);
    -o-transform: rotate3d(1.3, -2, 5, 70deg);

    /*transform: rotate3d(1, -3, 4, 56deg);
    -webkit-transform: rotate3d(1, -3, 4, 56deg);
    -moz-transform: rotate3d(1, -3, 4, 56deg);
    -ms-transform: rotate3d(1, -3, 4, 56deg);
    -o-transform: rotate3d(1, -3, 4, 56deg);
    */

    /*transform: rotate3d(1, 1, 3, 290deg);
    -webkit-transform: rotate3d(1, 1, 3, 290deg);
    -moz-transform: rotate3d(1, 1, 3, 290deg);
    -ms-transform: rotate3d(1, 1, 3, 290deg);
    -o-transform: rotate3d(1, 1, 3, 290deg);
    */

    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}





.eachBenefitWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}
.eachBenefitIconWrapper{
    width: 10%;
    
}
.eachBenefitIcon{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffcb08;
    background-color: #ffc90825;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachBenefitDescriptionWrapper{
    width: 90%;
    /* text-align: left; */
}





.termsConditionsWrapper {
    width: 60%;
    height: 87svh;
    /* background-image: linear-gradient(180deg, white, transparent, white); */
    
}

.termsContainer{
    margin: 3.5svh 5%;
    height: calc(76svh - 2px);
    width: calc(86% - 2px);
    overflow-y: scroll;
    /* border: 1px solid rgba(255, 255, 255, 0.486); */
    background-color: #FFFFFF2B;
    backdrop-filter: saturate(180%) blur(10px);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
/* 
    background-image: url('https://pbs.twimg.com/media/FUJ8dDrXEAIGk3o.jpg:large');
    opacity: 0.3; */
    padding: 2svh 2%;
    color: #dfdfdf;
}


.mainTitle{
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.mainTitle h4{
    padding: 0px;
    margin: 0px;
}

.sectionWrapper{
    padding: 20px 0px 0px 0px;
}
.sectionTitle{
    font-size: 1rem;
    font-weight: 600;
}