.mainWrapper{
    width: 100%;
    height: 93svh;
}
.mainContainer{
    width: 100%;
    height: 93svh;
    display: flex;
}
.leftColWrapper{
    width: 5%;
    height: 93svh;
    border-right: 1px solid #dfdfdf;
}
.leftColContainer{
    display: flex;
    height: 89svh;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 2svh 0px;
}
.eachIcon{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    background-color: #dfdfdf45;
    cursor: pointer;
}
.eachIcon:hover{
    background-color: rgb(217, 243, 168);
}
.leftWrapper{
    width: 70%;
    border-right: 1px solid #dfdfdf;
    height: 93svh;
}
.leftHeader{
    height: calc(5.5svh - 1px);
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}
.leftBody{
    height: 87.5svh;
}

.rightWrapper{
    width: 30%;
}


.leftProfileSynop{
    display: flex;
    padding: 12px 0px 6px 1.4%;
    align-items: baseline;
    /* border-bottom: 1px solid #dfdfdf; */
    justify-content: space-between;
    align-items: center;
}
.lpsWrapper{
    display: flex;
    align-items: center;
}
.clientName{
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: capitalize;
}
.gender{
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000086;
    padding: 0px 0px 0px 6px;
}
.mobile{
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 6px;
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000075;
}
.subMenusWrapper{
    width: 100%;
}
.tabsWrapper{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    padding: 0px 0%;
}
.uhidWrapper{
    display: flex;
    gap: 10px;
    padding: 0px 10px;
    font-size: 0.8rem;
    font-weight: 600;
}
.eachTab{
    border: none;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    padding: 10px 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #818181;
}
.activeTab{
    border: none;
    border-bottom: 2px solid #027FFF;
    background-color: transparent;
    padding: 10px 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
}


