.mainWrapper{
    width: 100%;
}
.tableWrapper{
    width: 100%;
    border: 1px solid #dfdfdf;
    min-height: 80svh;
}
.tableHeader{
    width: 100%;
    background-color: #dfdfdf;
}
.topRow{
    padding: 10px 0px;
}
.eachId{
    padding: 7px 5px;
    cursor: pointer;
}
.topRow th{
    padding: 7px 5px;
}
.tableHeader th{
    text-align: left;
}
.searchBar{
    padding: 7px 10px;
}
.searchBarWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}


.paginationWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.paginationContainer{
    width: 60%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    overflow-x: scroll;
}
.eachActivePageCount{
    font-weight: 600;
    padding: 8px 2px;
    border-bottom: 2px solid #FB651F;
}
.eachPageCount{
    font-weight: 500;
    padding: 8px 2px;
    border-bottom: 2px solid transparent;
}

.searchBarWrapper input{
    font-size: 0.8rem;
    margin: 0px 10px 0px 0px;
    border: 1px solid #dfdfdf;
    width: 400px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}