.mainWrapper{
    height: calc(93svh - 2px);
    width: 100%;
    background-color: aliceblue;
}
.mainContainer{
    height: calc(93svh - 2px);
    width: 100%;
}
.mainHeader{
    height: calc(8svh - 3px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0px 1%;
    overflow: hidden;
    background-color: aliceblue;
}
.mainBody{
    height: 85svh;
    overflow-y: scroll;
    padding: 0px 1%;
}

.eachSectionWrapper{
    width: 100%;
    height: 85svh;
}
.thatsAll{
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
}
.eachBookingWrapper{
    border: 1px solid #dfdfdf;
    height: 50px;
}
.tabBtn{
    padding: 9px 15px;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 600;
    font-size: 0.8rem;
    width: 110px;
}
.tabBtnActive{
    width: 110px;
    padding: 9px 15px;
    background-color: transparent;
    border: 1px solid #1f7af4;
    background-color: #1f7af430;
    color: #1f7af4;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 600;
    font-size: 0.8rem;
}