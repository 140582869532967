.showingTxt{
    font-size: 0.7rem;
    font-style: italic;
    padding: 8px 10px;
    color: #818181;
}
.allCardsWrapper{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 1%;
}
.eachCardWrapper{
    border: 1px solid #cecece !important;
    border-radius: 6px;
    padding: 8px 10px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: rgba(226, 226, 226, 0.473);
    color: #636262;
}
.addNewCardWrapper{
    border: 1px solid #cecece !important;
    border-radius: 6px;
    padding: 8px 10px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: rgba(226, 226, 226, 0.473);
    display: flex;
    align-items: center;
    margin: 0px 0px 0px 10px;
    width: 220px;
    font-size: 0.7rem;
    font-weight: 500;
    color: #818181;
}
.cardIdentifier{
    font-weight: 600;
    letter-spacing: 1.3px;
}
.bottomRow{
    display: flex;
    justify-content: space-between;
}
.cardName{
    font-size: 0.7rem;
    font-weight: 600;
}
.availableBalance{
    font-weight: 700;
    font-size: 0.7rem;
}