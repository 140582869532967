.mainWrapper{
    border: 1px solid#DDE3EC;
    padding: 15px 1.5%;
    margin: 0px 2% 10px 0px;
    background-color: #FFFEFD;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.mainContainer{
    padding: 0px;
}
.selectedStarIconFive{
    width: 45px;
    height: 45px;
    /* border: 1px solid #dde3ec; */
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: #4CEA4C;
    color: #000000;
}
.selectedStarIconFour{
    width: 45px;
    height: 45px;
    /* border: 1px solid #dde3ec; */
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: #A4FF4C;
    color: #000000;
}
.selectedStarIconThree{
    width: 45px;
    height: 45px;
    /* border: 1px solid #dde3ec; */
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: #FFCD4C;
    color: #000000;
}
.selectedStarIconTwo{
    width: 45px;
    height: 45px;
    /* border: 1px solid #dde3ec; */
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: #FF9F4C;
    color: #000000;
}
.selectedStarIconOne{
    width: 45px;
    height: 45px;
    /* border: 1px solid #dde3ec; */
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: #FF4C4C;
    color: #FFFFFF;
}


.mainHeader{
    display: flex;
    align-items: center;
}
.headerRightCol{
    display: flex;
    padding: 0px 1%;
    justify-content: space-between;
    width: 98%;
}
.clientName{
    font-weight: 600;
    color: #000000ce;
}
.visitedOn{
    font-size: 0.7rem;
    font-weight: 500;
    color: #0000006b;
}
.careToShareALilMoreTxt{
    margin: 7px 0px 0px 0px;
    padding: 5px 0px;
    color: #494949;
    text-align: justify;
    font-size: 0.8rem;
    font-style: italic;
}


.factorsWrapper{
    display: flex;
}
.eachFactorWrapperSe{
    margin: 7px 10px 0px 0px;
    padding: 7px 10px;
    min-width: 18%;
    font-size: 0.8rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #CFFAE4;
    color: #333333;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eachFactorWrapperSr{
    margin: 7px 10px 0px 0px;
    padding: 7px 10px;
    min-width: 18%;
    font-size: 0.8rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #FF9B3D40;
    color: #333333;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eachFactorWrapperSb{
    margin: 7px 10px 0px 0px;
    padding: 7px 10px;
    min-width: 18%;
    font-size: 0.8rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #A855F740;
    color: #333333;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.factorTitle{
    width: 40%;
}
.factorRating{
    font-size: 1.1rem;
    font-weight: 500;
}
.moreDetailsWrapper{
    width: 100%;
}
.moreDetailsBtn{
    display: flex;
    justify-content: flex-end;
    text-align: right;
    padding: 5px 0px;
    font-size: 0.7rem;
    color: #00000050;
    font-weight: 500;
    cursor: pointer;
}
.lessDetailsBtn{
    display: flex;
    justify-content: center;
}