.mainWrapper {
    height: 100svh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
}

.mainContainer {
    height: 100svh;
    width: 100%;
    background-color: #00000060;
    display: flex;
    justify-content: flex-end;
}

.mainContent {
    background-color: #F9FBFA;
    width: 40%;
    height: 100svh;
}

.mainHeader {
    height: 6svh;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 96%;
    padding: 0px 2%;
    background-color: white;
}

.mainHeader button {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 4px 8px;
    font-weight: 500;
}

.mainBody {
    padding: 0px 3%;
    overflow-y: scroll;
    gap: 10px;
    display: flex;
    flex-direction: column;
    height: 94svh;
}

.eachFieldWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.eachFieldWrapperPrice {
    display: flex;
    gap: 10px;
}


.efwInputWrapper {
    width: 100%;

}

.efwInputWrapper input {
    width: calc(100% - 12px);
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 10px 5px;
}

.efwSelectWrapper {
    width: 100%;

}

.efwSelectWrapper select {
    width: calc(100% - 0px);
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 9px 5px;
    cursor: pointer;
}

.eachFieldWrapper textarea {
    width: calc(100% - 18px);
    padding: 8px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

}

.fnsWrapper {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.fnsEachBig {
    flex: 2;
}

.fnsEach {
    flex: 1;
}

.durationWrapper {
    display: flex;
    gap: 10px;
}

select {
    padding: 7px 5px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
    min-width: 100px;
}

.frequencyContainer {
    display: flex;
    gap: 10px;
}

.frequencyContainer select {
    flex: 1;
}

.eachLabel {
    padding: 0px 0px 6px;
    font-weight: 500;
    font-size: 0.7rem;
}


.sectionWrapper{
    border: 1px solid #dfdfdf;
    padding: 10px 3% 10px;
    background-color: white;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.sectionWrapperThatsAll{
    padding: 10px 3% 10px;
}
.sectionHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sectionTitle{
    font-weight: 600;
    font-size: 0.9rem;
    color: #464343bf;
}
.sectionIcon{
    cursor: pointer;
    display: flex;
    align-items: center;
}
.sectionFieldWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.mtContentWrapper{
    padding: 10px 0px;
}
.mtContentContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.etw{
    display: flex;
    align-items: center;
    gap: 10px;
}
.etwCaption{
    flex: 1;
}
.etwValue{
    flex: 2;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.etwValue input{
    padding: 10px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.etwValue p{
    font-weight: 500;
    font-size: 0.7rem;
}

.thatsAll{
    text-align: center;
    color: #00000060;
    padding: 20px 0px 50px;
    font-size: 0.8rem;
}