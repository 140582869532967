.mainWrapper {
    width: 100%;
    height: 100vh;
}

.mainContainer {
    width: 100%;
    height: 100vh;
}




.mainBody{
    height: 93vh;
}
.contentBodyWrapper{
    width: 100%;
    height: 40vh;
    overflow: hidden;
    padding: 0;
    margin-bottom: 16px;
}
.contentBodyContainer{
    animation: 1s animLineUp ease-out 1;
    -webkit-animation: 1s animLineUp ease-out 1;
}

@keyframes animLineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }