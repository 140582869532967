.detailsWrapper{
    padding: 16px;
}

.actionWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 0px 15px;
    gap: 10px;
}
.actionWrapper button{
    padding: 8px 22px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border: 1px solid #dfdfdf;
}
.updateActive{
    padding: 8px 22px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border: 1px solid #027fff !important;
    background-color: #027fff20;
    color: #027fff;
    font-weight: 500;
}
.contentWrapper{
    padding: 20px;
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.clientName{
    padding: 10px 10px;
    border: 1px solid #dfdfdf;
    min-width: 250px;
}
.nameWrapper{
    display: flex;
    gap: 10px;
}
.nameWrapper input{
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.primaryPhoneWrapper{
    display: flex;
    align-items: center;
    gap: 10px;
}
.countryCode{
    width: 100px;
}
.countryCode select{
    width: 100px !important;
}
.mobile input{
    padding: 10px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.phoneNumberWrapper{
    display: flex;
    align-items: center;
}
.phoneNumbers{
    display: flex;
    gap: 10px;
}
.phoneNumbers input{
    padding: 10px 6px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    min-width: 250px;
}

.emailWrapper{
    display: flex;
    gap: 10px;
    align-items: center;
}
.emailWrapper input{
    padding: 9px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: 240px;
}
.age{
    padding: 9px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: #818181;
}



.fullName{
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 500;
}
.updatemobile{
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 500;
}
.uPhones{
    display: flex;
    gap: 20px;
}
.fTitle{
    font-size: 0.7rem;
    color: #818181;
}
.fValue{
    font-size: 0.9rem;
    color: #00000080;
    font-weight: 500;
    padding: 3px 0px 0px;
}
.otherField{
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.ageInputWrapper{
    display: flex;
    gap: 10px;
}
.address textarea{
    padding: 8px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    width: 60%;
}