.mainContainer{
    width: 96%;
    border-bottom: 1px solid #dfdfdf;
    padding: 4px 2%;
}
.header{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0px 0px;
}
.basePriceSummary{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    font-size: 10px;
}
.finalPriceSummary{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #dfdfdf80;
    font-size: 11px;
    padding: 4px 0px 10px;
}
.psideBySide{
    display: inline-flex;
    justify-content: space-between;
    width: 28%;
    align-items: baseline;
}
.fpOrg{
    font-size: 10px;
}
.fpDis{
    font-size: 14px;
}

.toBeUpdatedForm{
    display: inline-flex;
    justify-content: space-between;
    padding: 8px 0px 14px;
    width: 100%;
    align-items: baseline;
}
.updatingWhat{
    font-size: 12px;
}
.updatingForm input{
    border: none;
    border-bottom: 1px solid #a5a5a580;
    padding: 5px 10px;
    width: 40px;
    text-align: right;
    background-color: #dfdfdf80;
}
.updatingForm button{
    padding: 5px 10px;
    font-size: 12px;
    margin-left: 8px;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}