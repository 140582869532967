.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100svh;
}
.mainContainer{
    position: relative;
    display: flex;
    width: 100%;
    height: 100svh;
    background-color: #000000bf;
    justify-content: center;
    align-items: center;
}
.mainContent{
    background-color: white;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 2%;
    width: 30%;
}
.formWrapper{
    display: flex;
    gap: 13px;
    flex-direction: column;
}
.eachInput input{
    padding: 11px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: calc(100% - 22px);
}
.eachPassword{
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: 100%;
    display: flex;
    overflow: hidden;
}
.passwordToggle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    cursor: pointer;
}
.eachPassword input{
    flex: 3;
    padding: 11px 10px;
    border: none;
}
.eachPassword button{
    flex: 1;
    border: none;
    border-left: 1px solid #dfdfdf;
    font-weight: 500;
    font-size: 0.8rem;
}


.passwordToggle{
    display: flex;
    flex-direction: column;
}

.actionWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px 0px 0px;
    gap: 10px;
}
.done{
    padding: 12px 20px;
    background-color: #027FFF;
    border: 1px solid #027FFF;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    width: 200px;
}
.cancel{
    border: none;
    background-color: transparent;
    color: #a5a5a5;
    font-size: 0.7rem;
}

.roleWrapper{
    width: 100%;
}
.roleWrapper select{
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}



