.mainWrapper{
    height: 100svh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(34, 34, 34, 0.068);
    z-index: 2;
}
.mainContainer{
    position: relative;
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContent{
    width: 60%;
    height: 50svh;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.popUpTitle{
    text-align: center;
    padding: 14px 0px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.allWorkersWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.eachStaffWrapper{
    cursor: pointer;
    border: 1px solid #dfdfdf;
    padding: 8px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

    width: 100px;
    margin: 4px;
    text-align: center;
    background-color: #d1d1d154;
}
.eachStaffWrapperSelected{
    cursor: pointer;
    border: 1px solid #dfdfdf;
    padding: 8px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

    width: 100px;
    margin: 4px;
    text-align: center;
    background-color: #afe6a454;
}

.workersImg img{
    height: 50px;
    width: 50px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
