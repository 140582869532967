.mainWrapper{
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
}

.storeListColumn{
    width: 20%;
    height: 88vh;
    z-index: 2;
}
.slcHeader{
    background-color: azure;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0px 0px;
}
.checkBoxMain{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.headerTitle{
    font-weight: 500;
}
.headerSubTitle{
    font-size: 0.6rem;
    font-weight: 500;
    color: #00000065;
}
.slcBody{
    height: 80vh;
    overflow-y: scroll;
    background-color: white;
}
.storeServiceCategoryColumn{
    width: 25%;
    background-color: rgb(240, 240, 240);
    animation: openIt .2s cubic-bezier(1, 0.03, 0, 0.94);
    -webkit-animation: openIt .2s cubic-bezier(1, 0.03, 0, 0.94);
    overflow-x: hidden;
    z-index: 1;
}
.storeServiceCategoryHeader{
    height: 8vh;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    justify-content: space-between;
}
.searchTabCategory{
    width: 80%;
}
.searchTabCategory input{
    width: calc(100% - 16px);
    padding: 8px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.storeServiceAddonColumn{
    width: 25%;
    background-color: antiquewhite;
    animation: openIt .2s cubic-bezier(1, 0.03, 0, 0.94);
    -webkit-animation: openIt .2s cubic-bezier(1, 0.03, 0, 0.94);
    overflow-x: hidden;
    z-index: 0;
}

@keyframes openIt {
    0% {
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
}
    100% {
        transform: translateX(0%);
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
}
}


.storeServiceSelected{
    width: 30%;
    background-color: mistyrose;
    animation: bringItOn .2s cubic-bezier(1, 0.03, 0, 0.94);
    -webkit-animation: bringItOn .2s cubic-bezier(1, 0.03, 0, 0.94);
}

@keyframes bringItOn {
    0%{
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
    }
    100%{
        transform: translateX(0%);
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
    }
}