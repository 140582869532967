.rightHeader{
    height: calc(5.5svh - 1px);
    width: 94%;
    padding: 0px 3%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rhTitle{
    width: 30%;
    font-weight: 600;
    text-transform: uppercase;
    color: #027FFF;
}
.rhActions{
    display: flex;
    width: 70%;
    justify-content: flex-end;
}
.eachActionBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    margin: 0px 4px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.rightBody{
    height: 87.5svh;
}