.mainWrapper {
    top: 0px;
    left: 0px;
    position: fixed;
    width: 100%;
    height: 100svh;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.37);
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.mainContainer {
    position: relative;
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: flex-end;
}

.extenderWrapper {
    width: 35%;
    height: 100svh;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loaderImg img{
    height: 100px;

}


.extenderWrapperHeader {
    height: 6svh;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.extenderWrapperHeader button{
    background-color: transparent;
    border: none;
    color: #5a5a5a;
}

.extenderWrapperBody {
    height: 64svh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
}
.extenderWrapperBodyLoader{
    height: 64svh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
}
.extenderWrapperFooter{
    height: 30svh;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.ewbTitle {
    padding: 10px 0px 0px;
}
.ewbTitleVs{
    text-align: center;
    padding: 6px 0px;
}
.ewbTitleSmall{
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 1.2px;
}

.amountWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    padding: 20px 0px;
}

.amountContainer {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
}

.rupeeIcon {
    color: #858585;
}

.amountContainer input {
    border: none;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    width: 10px;
    padding: 6px 4px;
    margin: 8px 0px;
    background-color: #e6e6e6;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

}

.amountContainer input::-webkit-outer-spin-button,
.amountContainer input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.epmRight input::-webkit-outer-spin-button,
.epmRight input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.pinWrapper{
    padding: 1svh 7%;
    width: 86%;
    height: 30svh;
    display: flex;
    flex-direction: column;
    align-items: space-between;
}
.pinTitleWrapper{
    text-align: center;
    padding: 5px 0px;
    font-size: 0.8rem;
    font-weight: 600;
}
.pinTitleWrapperDanger{
    text-align: center;
    padding: 5px 0px;
    font-size: 0.8rem;
    font-weight: 600;
    color: red;
}
.upperRow{
    text-align: center;
    display: flex;
    justify-content: center;
}
.rightArrowBtn{
    width: 44px;
    height: 44px;
    background-color: #FB651F;
    border-radius: 44px;
    -webkit-border-radius: 44px;
    -moz-border-radius: 44px;
    -ms-border-radius: 44px;
    -o-border-radius: 44px;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    cursor: pointer;
    border: none;

}
.lowerRow{
    padding: 4px 10%;
    display: flex;
    justify-content: flex-end;
}
.pinBoxes{
    padding: 5px 8px;
}
.pinBoxes input{
    padding: 10px 12px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 600;
}

.valueAdditionWrapper{
    display: flex;
    text-align: center;
    justify-content: center;
    height: 30px;
    font-weight: 500;
    color: #00000070;
}
.creditableAmountWrapper{
    display: flex;
    align-items: center;
    height: 25px;
    text-align: center;
    font-weight: 600;
    color: #00000085;
}
.creditableAmountLoader{
    margin-bottom: 0px !important;
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -ms-border-radius: 4px !important;
    -o-border-radius: 4px !important;
}
.creditableAmountTxt{
    min-width: 80px;
}

.payModeWrapper{
    width: 70%;
    padding: 4px 15%;
    margin: 20px 0px;
}
.eachPayModeLoader{
    display: flex;
    align-items: center;
    /* border: 1px solid #dfdfdf; */
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    width: 100%;
    margin: 8px 0px 8px;
    justify-content: space-between;
}
.eachModeLoader{
    margin-bottom: 0px !important;
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -ms-border-radius: 4px !important;
    -o-border-radius: 4px !important;
}

.eachPayMode{
    display: flex;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    
    margin: 8px 0px 8px;
    justify-content: space-between;
}
.epmLeft{
    padding: 5px 10px;
    width: calc(50% - 20px);
    background-color: #dfdfdf;
    display: flex;
    align-items: center;
    border-right: 1px solid #dfdfdf;
}
.leftIconWrapper{
    padding: 0px 10px 0px 0px;
}
.epmRight{
    width: calc(50% - 1px);
}
.modeTxt{
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: #00000070;
}
.epmRight input{
    width: calc(100% - 20px);
    padding: 10px 10px;
    border: none;
    text-align: right;
}