.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100svh;
    z-index: 9999999;
}
.mainContainer{
    position: relative;
    width: 100%;
    height: 100svh;
    background-color: #000000bf;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroundImage{
    height: 460px;
    width: 460px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}
.backgroundImage img{
    width: 100%;
}