.mainWrapper{
    background-color: aliceblue;
    width: 100%;
}
.mainContainer{
    width: 100%;
    height: 100svh;
}
.mainHeader{
    height: 8svh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.bodyContainer{
    width: 100%;
    height: 92svh;
}

.clientTable{
    height: 92svh;
    overflow-y: scroll;
}

.addNewClientBtn button{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: 0px 10px 0px 0px;
    padding: 6px 14px;
    border: 1px solid #ff8450;
    background-color: #ff8450;
    color: white;

    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}