.eachTransactions{
    padding: 10px 3%;
    display: flex;
    justify-content: space-between;
}
.transIconWrapper{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.transIconBox{
    background-color: #E4EDE8;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.captionWrapper{
    width: 31%;
    padding: 10px 2%;
}
.transactionCaption{
    font-weight: 600;
    font-size: 1rem;
}
.transactionType{
    font-weight: 600;
    font-size: 0.7rem;
    color: #dfdfdf;
}
.timeWrapper{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    letter-spacing: 1.1px;
    font-weight: 600;
    color: #dfdfdf;
}
.amountWrapper{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
.actionsWrapper{
    width: 8%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 1%;
}
.eachActionWrapper{
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    /* background-color: #E4EDE8; */
    border-radius: 35px;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    -o-border-radius: 35px;
}