.mainWrapper{
    display: flex;
    padding: 13px 0px;
    width: 100%;
}

.mainContainer{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}
.userIcon{
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #027FFF;
    border-radius: 44px;
    -webkit-border-radius: 44px;
    -moz-border-radius: 44px;
    -ms-border-radius: 44px;
    -o-border-radius: 44px;
    background-color: white;
}
.userDetails{
    flex: 2;
}
.fullName{
    padding: 0px 0px 2px;
    font-weight: 500;
    font-size: 0.9rem;
}
.age{
    font-size: 0.7rem;
    font-weight: 500;
    color: #818181;
}

.relation{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.relationToFamilyHead{
    font-size: 0.6rem;
    border: 1px solid #dfdfdf;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    padding: 4px 10px;
    font-weight: 500;
    text-transform: capitalize;
    background-color: #dfdfdf50;
}
.actionsWrapper{
    flex: 1;
    gap: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.actionsWrapper button{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #027fff;
    height: 35px;
    width: 35px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    background-color: #027fff40;
    color: #027fff;
}