@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
span,
select, textarea {
  font-family: 'Montserrat';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: black;
  text-decoration: none;
}

@keyframes openAddNewClientPanel {
  0%{
    left: 100%;
  }
  100%{
    left: 0%;
  }
}
@keyframes closeAddNewClientPanel {
  0%{
    left: 0%;
  }
  100%{
    left: 100%;
  }
}


@-webkit-keyframes slideRightToLeft {
  0% {
    left: 0%;
  }

  100% {
    left: -100%;
  }
}

@keyframes slideRightToLeft {
  0% {
    left: 0%;
  }

  100% {
    left: -100;
  }
}

@-webkit-keyframes slideLeftToRight {
  0% {
    left: -100%;
  }

  100% {
    left: 0%;
  }
}

@keyframes slideLeftToRight {
  0% {
    left: -100%;
  }

  100% {
    left: 0%;
  }
}
@keyframes insertFromRight {
  0% {
    left: 100%;
  }

  100% {
    left: 0%;
  }
}


@keyframes showBuyForm {
  0% {
    left: 40%;
  }

  100% {
    left: 0%;
  }
}

@-webkit-keyframes showBuyForm {
  0% {
    left: 40%;
  }

  100% {
    left: 0%;
  }
}




@keyframes rotation {
  0% {
    transform: rotateY(0);
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    -ms-transform: rotateY(0);
    -o-transform: rotateY(0);
  }
/* 
  5% {
    transform: rotateY(359deg);
    -webkit-transform: rotateY(359deg);
    -moz-transform: rotateY(359deg);
    -ms-transform: rotateY(359deg);
    -o-transform: rotateY(359deg);
  }

  10% {
    transform: rotateY(0);
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    -ms-transform: rotateY(0);
    -o-transform: rotateY(0);
  }

  15% {
    transform: rotateY(359deg);
    -webkit-transform: rotateY(359deg);
    -moz-transform: rotateY(359deg);
    -ms-transform: rotateY(359deg);
    -o-transform: rotateY(359deg);
  }

  20% {
    transform: rotateY(0);
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    -ms-transform: rotateY(0);
    -o-transform: rotateY(0);
  } */

  100% {
    transform: rotateY(359deg);
    -webkit-transform: rotateY(359deg);
    -moz-transform: rotateY(359deg);
    -ms-transform: rotateY(359deg);
    -o-transform: rotateY(359deg);
  }
}



.shimmer-button{
  margin: 0px !important;
}



@keyframes slideBottomToTop {
  0% {
    top: 100%;
  }

  100% {
    top: 0%;
  }
}

@keyframes slideTopToBottom {
  0% {
    top: -100%;
  }

  100% {
    top: 0%;
  }
}



@keyframes reduceFontSize {
  0% { font-size: 1.1rem;}
  100% {font-size: 0.7rem;}
}

@keyframes increaseFontSize {
  0% { font-size: 0.7rem;}
  100% { font-size: 1.1rem;}
}



.react-datepicker__header {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #646464 !important;
  font-family: 'Montserrat' !important;
}



