.mainWrapper{
    height: calc(93svh - 3px);
    width: 100%;
    display: flex;

}
.priceListWrapper{
    width: 60%;
    height: calc(93svh - 3px);
    border-right: 1px solid #dfdfdf;
}
.priceListHeader{
    height: calc(6svh - 1px);
    border-bottom: 1px solid #dfdfdf;
}
.plhUpper{
    width: 96%;
    padding: 0px 2%;
    display: flex;
    justify-content: space-between;
    height: calc(6svh - 1px);
    align-items: center;
}
.searchBar{
    width: 50%;
}
.searchBar input{
    font-size: 0.7rem;
    width: calc(96% - 2px);
    padding: 8px 2%;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.filterWrapper{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.priceListBody{
    height: 87svh;
    background-color: white;
}
.priceListContainer{
    height: 85svh;
    width: 100%;
    overflow-y: scroll;
}

.cartWrapper{
    width: 40%;
}


.eachFilterBtn{
    padding: 4px 6px;
    font-size: 0.6rem;
    margin: 0px 6px 0px 0px;
    cursor: pointer;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    text-transform: uppercase;
}
.eachFilterBtnActive{
    padding: 4px 6px;
    font-size: 0.6rem;
    margin: 0px 6px 0px 0px;
    cursor: pointer;
    background-color: #027FFF20;
    font-weight: 600;
    text-transform: uppercase;
    color: #027FFF;
    border: 1px solid #027FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.moreBtn{
    background-color: rgba(43, 43, 43, 0.678);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(53, 53, 53);
    height: 30px;
    width: 30px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}


.noPriceListWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70svh;
    flex-direction: column;
}
.noPriceListTxt{
    font-weight: 600;
    color: #cacaca;
}
.goToServicesBtn{
    padding: 30px 0px;
}
.addServices{
    padding: 8px 20px;
    cursor: pointer;
}