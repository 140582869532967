.actionWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}
.eachActionWrapper{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    background-color: #FB651F;
    color: white;
}