.mainWrapper{
    flex: 1;
    padding: 2%;
}
.mainContainer{
    flex: 1;
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    height: 84svh;
}
.mainContent{
    display: flex;
    flex-direction: column;
}
.mainHeader{
    height: calc(6svh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    padding: 0px 1.5%;
}
.titleWrapper{
    flex: 2;
    font-size: 0.7rem;
    font-weight: 500;
    color: #a5a5a5;
}
.btnWrapper{
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.btnWrapper button{
    padding: 6px 10px;
    border: 1px solid #027FFF;
    font-weight: 500;
    font-size: 0.7rem;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: #027FFF;
    color: white;
}
.mainBody{
    height: 78svh;
}

.tableWrapper{
    height: 78svh;
}
.tableBody{
    height: 73svh;
}
.tableBodySmall{
    height: 63svh;
}
.createNewWrapper{
    display: flex;
    justify-content: center;
    height: 10svh;
    align-items: center;
    gap: 20px;
}
.cnInput{
    flex: 2;
    display: flex;
    justify-content: flex-end;
}
.cnInput input{
    padding: 8px 10px;
    width: 70%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.cnBtn{
    flex: 1;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
}
.cnBtn .create{
    padding: 8px 30px;
    background-color: #027FFF;
    color: white;
    font-weight: 500;
    border: 1px solid #027FFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.cnBtn .cancel{
    padding: 6px 24px;
}
.tableHeader{
    font-weight: 600;
    height: 5svh;
    background-color: #dfdfdf30;
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #027FFF30;
    height: 5svh;
    align-items: center;
    font-size: 0.8rem;
}



.sn{
    flex: 0.5;
    text-align: center;
}
.pageUrl{
    flex: 4;
}
.status{
    flex: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.actions{
    flex: 2.8;
    display: flex;
    gap: 10px;
}
