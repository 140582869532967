.eachCategoryWrapper{
    padding: 8px 2%;
}
.categoryTitle{
    font-weight: 600;
    font-size: 1.2rem;
}
.categoryServicesWrapper {
    width: 100%;
    padding: 0px 0px 20px;
    border-bottom: 1px dashed #cacaca;

}