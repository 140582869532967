.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100svh;
}
.mainContainer{
    position: relative;
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000bb;
}
.mainContent{
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    background-color: white;
    width: 75%;
    height: 80svh;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.titleWrapper{
    height: 6svh;
    display: flex;
    align-items: center;
    padding: 0px 2%;
    text-align: center;
    color: #027fff;
    width: 96%;
    justify-content: center;
    font-weight: 500;
}
.tableWrapper{
    height: 66svh;
}

.tableHeader{
    display: flex;
    border-top: 1px solid #dfdfdf;
    padding: 0px 0px;
    border-bottom: 1px solid #dfdfdf;
    background-color: #dfdfdf40;
    font-weight: 500;
    height: 5svh;
    align-items: center;
    font-size: 0.8rem;
    text-transform: uppercase;
}
.tableHeader .sn{
    height: 5svh;
    width: 3%;
    padding: 0px 1%;
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
}
.tableHeader .category{
    height: 5svh;
    width: 18%;
    padding: 0px 1%;
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    font-size: 13px;
}
.tableHeader .expense{
    height: 5svh;
    width: 43%;
    padding: 0px 1%;
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
}

.tableHeader .amount{
    width: 13%;
    padding: 0px 1%;
    height: 5svh;
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    text-align: right;
}
.tableHeader .action{
    width: 10%;
    height: 5svh;
    padding: 0px 1%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
}
.removeBtn{
    font-size: 0.8rem;
    border: 1px solid #dfdfdf;
    padding: 8px 8px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addBtn{
    font-size: 0.8rem;
    border: 1px solid #dfdfdf;
    padding: 8px 8px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tableDataWrapper{
    height: 57svh;
    overflow-y: scroll;
}
.rowWrapper{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
}




.rowWrapper .sn{
    width: 3%;
    padding: 0px 1%;
    border-right: 1px solid #dfdfdf;
}
.rowWrapper .category{
    width: 20%;
    padding: 0px 0%;
    border-right: 1px solid #dfdfdf;
    /* min-height: 15svh; */
}
.rowWrapper .category select{
    width: 100%;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    text-transform: uppercase;
}
.rowWrapper .expense{
    width: 45%;
    padding: 0px 0%;
    border-right: 1px solid #dfdfdf;
    /* min-height: 15svh; */
}
.expenseName{
    width: 100%;
}
.expenseName input{
    width: calc(100% - 20px);
    padding: 10px 10px;
    border: none;
    border-bottom: 1px solid #dfdfdf;
}
.expenseName input:focus{
    background-color: aliceblue;
}
.rowWrapper .expenseNotes{
    overflow: hidden;
    height: calc(10svh - 16px);
}
.rowWrapper .expenseNotes textarea{
    width: calc(100% - 20px);
    border: none;
    padding: 10px 10px;
    height: calc(10svh - 16px);
}
.rowWrapper .expenseNotes textarea:focus{
    background-color: aliceblue;
}
.rowWrapper .expenseNotes textarea::placeholder{
    color: #81818180;
    font-size: 13px;
}
.rowWrapper .amount{
    width: 15%;
    padding: 0px 0%;
    border-right: 1px solid #dfdfdf;
    /* min-height: 15svh; */
}
.rowWrapper .amount input{
    width: calc(100% - 10px);
    padding: 10px 5px;
    border: none;
    text-align: right;
}
.rowWrapper .amount input::placeholder{
    color: #81818180;
    font-size: 13px;
}
.rowWrapper .amount input:focus{
    background-color: aliceblue;
}
.rowWrapper .action{
    width: 10%;
    padding: 0px 1%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 0.8rem;
    gap: 10px;
    justify-content: flex-end;
    text-align: right;
}
.footer{
    height: 8svh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 2%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    gap: 10px;
}
.backBtn{
    padding: 9px 15px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #dfdfdf;
    font-weight: 600;
    width: 100px;
}
.updateBtn{
    padding: 9px 15px;
    background-color: #027fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #027fff;
    color: white;
    font-weight: 600;
    width: 100px;
}


.paidWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
}
.paidContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.eachOption{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
}
.eoTitle{
    flex: 1;
}
.eoValue{
    flex: 1;
    text-align: right;
    font-weight: 600;
    font-size: 1.1rem;
}
.eoValue input{
    text-align: right;
    padding: 8px 10px;
    border: none;
    border-bottom: 1px solid #dfdfdf;
}

.total{
    background-color: aliceblue;
    padding: 10px 10px;
}