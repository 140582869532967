.cartItemWrapper{
    width: 94%;
    display: flex;
    justify-content: space-between;
    padding: 10px 3%;
}
.serviceName{
    font-weight: 500;
    font-size: 1rem;
}
.quantityWrapper{
    display: flex;
    background-color: #027FFF;
    color: white;
    align-items: center;
    justify-content: space-between;
    width: 84px;
    font-size: 0.8rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.quantityWrapper button{
    background-color: transparent;
    color: white;
    border: none;
    padding: 5px 10px;
}


.addOn{
    font-size: 0.6rem;
    font-weight: 600;
    color: #686868;
}
.staffName{
    font-size: 0.7rem;
    font-weight: 600;
    color: #4b4b4b;
    display: flex;
    align-items: center;
    gap: 5px;
}

.servPrice{
    font-size: 0.9rem;
    font-weight: 600;
    color: #4b4b4b;
    text-align: right;
    padding: 4px 0px 0px 0px;
}

.loaderBtn{
    overflow: hidden;
    width: 100px;
    border: 1px solid #dfdfdf;
    height: 24px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}   
