
.mainWrapper{
    border: 1px solid rgb(139, 139, 139);
    margin: 5%;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.mainHeader{
    color: #dfdfdf;
}
.mainHeaderUpper{
    height: 4svh;
    display: flex;
    justify-content: space-between;
    padding: 20px 3% 10px;
    align-items: center;
}
.mainHeaderLower{
    display: flex;
    padding: 0px 3%;
}
.eachFilter{
    border: 1px solid #dfdfdf;
    padding: 4px 15px;
    font-size: 0.7rem;
    font-weight: 600;
    background-color: #dfdfdf;
    margin: 0px 10px 0px 0px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.headerTitle{
    font-weight: 600;
}
.headerIcon{
    height: 34px;
    width: 34px;
    border: 1px solid #dfdfdf;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.mainBody{
    height: 56svh;
    overflow-y: scroll;
}