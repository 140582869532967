.addOnClientsContainer{
    border: 1px solid #dfdfdf80;
    margin: 10px 5% 0px;
    
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #dfdfdf80;
    height: 60svh;
    overflow-y: scroll;
}
.loadingTxt{
    padding: 10px 5%;
}
.eachAddOnAdd{
    width: 100%;
    justify-content: center;
    text-align: center;
    padding: 10px 0px;
}
.eachAddOnAddBtn{
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #dfdfdf80;
    padding: 8px 20px;
    cursor: pointer;
}
.newAddOnContainer{
    display: inline-flex;
    flex-direction: column;
    width: 84%;
    margin: 10px 5%;
    background-color: #a5a5a560;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 8px 3%;
}
.brandNameContainer{
    width: 100%;
}
.brandNameContainer input{
    width: calc(94% - 2px);
    padding: 8px 3%;
    border: none;
    border-bottom: 1px solid #a5a5a560;
    margin: 10px 0px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.addOnPriceContainer{
    width: 100%;
    justify-content: flex-end;
    text-align: right;
}
.addOnPriceContainer input{
    width: calc(24% - 2px);
    padding: 8px 3%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    margin: 0px 0px 0px 5%;
    text-align: right;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
input:focus{
    outline: none;
}
.addAddOnBtn{
    width: 100%;
    justify-content: flex-end;
    text-align: right;
    padding: 10px 0px;
}
.addAddOnBtn button{
    padding: 7px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}

.dynamicPriceContainer{
    display: inline-flex;
    flex-direction: column;
}
.sideBySideBase{
    font-size: 10px;
    display: inline-flex;
    justify-content: space-between;
    padding-bottom: 8px;
}
.sideBySideFinal{
    font-size: 12px;
    display: inline-flex;
    justify-content: space-between;
    padding: 8px 0px 0px;
    border-top: 1px solid #a5a5a560;
}

.pSideBySide{
    display: inline-flex;
    justify-content: space-between;
    width: 44%;
}
.noAddOnsFound{
    text-align: center;
    background-color: #dfdfdf60;
    margin: 10px 5%;
    padding: 20px 3%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.addOnCatTitle{
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    padding: 10px 0px;
}