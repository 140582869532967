.mainContainer{
    width: calc(100% - 24px);
    padding: 10px 12px;
    border-bottom: 1px solid #dfdfdf80;
}
.topContainer{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
.discountedContainer{
    font-size: 11px;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
.actionContainer{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 8px 0px 0px;
}
.eachActionBtn{
    border: 1px solid #a5a5a5;
    padding: 6px 14px;
    font-size: 11px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #dfdfdf80;
    cursor: pointer;
}