.mainWrapper{
    display: flex;
    flex: 1;
    background-color: #F9F9F9;
    height: 92svh;
    overflow-y: scroll;
}
.mainContainer{
    padding: 2svh 2%;
    flex: 1;
}
.mainContent{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.upperHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hiname{
    font-weight: 500;
    font-size: 1.6rem;
    padding: 8px 0px;
}
.proName{
    font-size: 0.6rem;
    color: #848484;
    font-weight: 600;
}
.addClient{
    background-color: #007FFF;
    color: white;
    font-weight: 600;
    font-size: 0.7rem;
    padding: 8px 15px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}



.bodyWrapper{
    background-color: white;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    border: 1px solid #007FFF60;
    overflow: hidden;
}

.tableHeader{
    height: 5vh;
    display: flex;
    gap: 10px;
    display: flex;
    align-items: center;
    background-color: #007FFF30;
    color: #007FFF;
    font-weight: 600;
    font-size: 0.8rem;
}

.sn{
    flex: 0.4;
    text-align: center;
}
.invoice{
    flex: 1;
}
.client{
    flex: 3;
}
.amount{
    flex: 0.7;
}
.status{
    flex: 1.4;
}
.action{
    flex: 1;
}

.tableBody{
    height: 72vh;
    overflow-y: scroll;
}
.eachRow{
    display: flex;
    color: #818188;
    font-size: 0.8rem;
    border-bottom: 1px solid #81818130;
    align-items: center;
    gap: 10px;
}
.action{
    padding: 14px 0px;
}
.action a{
    padding: 6px 15px;
    border: 1px solid #007FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-size: 0.7rem;
    background-color: #007FFF;
    color: white;
    font-weight: 500;
}

.downloadAction{
    display: flex;
    gap: 10px;
}

.downloadBtn{
    padding: 7px 16px;
    font-size: 0.7rem;
    border: 1px solid #007FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: #007FFF10;
    color: #007FFF;
    font-weight: 500;
}